import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import historia1 from "../../assets/img/history/historia1.jpg";
import historia2 from "../../assets/img/history/historia2.jpg";
import historia3 from "../../assets/img/history/historia3.jpg";
import historia4 from "../../assets/img/history/historia4.jpg";
import historia5 from "../../assets/img/history/historia5.jpg";
import historia6 from "../../assets/img/history/historia6.jpg";
import historia7 from "../../assets/img/history/historia7.jpg";
import historia8 from "../../assets/img/history/historia8.jpg";
import historia9 from "../../assets/img/history/historia9.jpg";
import ScrollToTop from "../../components/ScrollToTop";
import Banner from "../../components/Banner/Banner";

function HistoryPage() {
  const currentYear = new Date().getFullYear();
  const [expandedSection, setExpandedSection] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000, // Duración de la animación en milisegundos
    });
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      AOS.refresh(); // Reinicia AOS después de que las imágenes se hayan cargado
    }
  }, [imagesLoaded]);

  const handleExpand = (id) => {
    setExpandedSection(expandedSection === id ? null : id);
  };

  const handleImageLoad = () => {
    const allImages = document.querySelectorAll("img");
    let loadedImages = 0;

    allImages.forEach((img) => {
      if (img.complete) {
        loadedImages++;
      } else {
        img.onload = () => {
          loadedImages++;
          if (loadedImages === allImages.length) {
            setImagesLoaded(true);
          }
        };
      }
    });

    if (loadedImages === allImages.length) {
      setImagesLoaded(true);
    }
  };

  useEffect(() => {
    handleImageLoad();
  }, []);

  const historysData = [
    {
      id: 1,
      imgSrc: historia1,
      imgAlt:
        "Estancia de Juan Gregorio Girado cerca de la laguna de Chascomús",
      orderLast: true,
      year: 1789,
      description: `Juan Gregorio Girado (1750-1819), integrante del cuerpo de Blandengues que fundó Chascomús (1779), obtuvo hacia 1789 una
        fracción de tierra al NE de la laguna de Chascomus. Más de 10 mil hectáreas despobladas fueron el suelo fértil donde este hombre,
        construyó su estancia a la vera de la laguna, plantando mas de treinta hectáreas de álamos, paraísos y diversas especies frutales,
        montando aljibes y jagüeles para la obtención de agua potable, y otras muchas instalaciones, puestos, galpones y corrales para el
        alojamiento de los peones, la guarda de las herramientas y la cría de ganado. La casa estaba cercada por muros y rejas, y coronada
        con una terminación almenada que servía de defensa en caso de sufrir algún ataque. Por lo menos dos cañones de aviso fueron parte
        del pequeño arsenal que les servía de protección. En sus alrededores, una toldería de indios amigos fue testigo de su progreso y fortaleza. Juan Gregorio, retirado como Sargento Mayor del Cuerpo de Blandengues  y premiado en diversas oportunidades por la entereza de su accionar, estuvo casado con doña Hilaria Avalos y Rivera, quien  a la muerte de su marido supo llevar adelante los negocios familiares con mano firme. Juntos fueron desarrollando diversas explotaciones que trascendieron la frontera de Chascomús, extendiéndose al principio hasta los pagos del Tuyú y Pila.`,
    },
    {
      id: 2,
      imgSrc: historia2,
      imgAlt: "Hilaria Avalos y Rivera y sus 11 hijos en la estancia",
      orderLast: false,
      year: 1815,
      description: `Juan Gregorio e Hilaria tuvieron 11 hijos que fueron los herederos de la vieja Alameda.En 1815 Hilaria es censada en el pueblo, lo que demuestra que ya vivían allí, y por lo tanto nos da una idea de la antigüedad del casco. Si bien al principio se presume que habitaron un rancho muy sencillo,paulatinamente se fue ampliando acorde a las necesidades mínimas de su familia numerosa. Sus dimensiones fueron variando acorde a la condición social de los propietarios, los requisitos de uso familiar, y al crecimiento de la producción y comercialización, hasta poseer las características propias de las estancias bonaerenses y de la arquitectura rural de la provincia.`,
    },
    {
      id: 3,
      imgSrc: historia3,
      imgAlt: "Jose Francisco Girado gestionando la posesión de tierras",
      orderLast: true,
      year: 1819,
      description: `Uno de sus hijos, Jose Francisco, en 1819 gestiona la posesión de las tierras ante el gobierno, considerando los méritos de su padre en las guerras, así como su apoyo personal a través de préstamos de caballos y ganado en todas las expediciones realizadas.`,
    },
    {
      id: 4,
      imgSrc: historia4,
      imgAlt: "Juan Elías Girado y la modernización de la estancia",
      orderLast: false,
      year: 1834,
      description: `Hacia 1834, fue Juan Elías, uno de los hijos más pequeños, quien tomó las riendas de la explotación, transformando La Alameda en una estancia de avanzada, poblada con ganado Shortorn, raza señera en el desarrollo pecuario de la pampa húmeda y ovejas Merino, buscando con ello mejorar la calidad de sus rodeos y de la explotación agrícola en general.Mas tarde, los viejos cañones quedarían amojonando los lindes de la estancia dando paso al alambrado y las maquinarias agrícolas que los herederos de Juan Elías, adoptaron enseguida para mejorar sus explotaciones, extendiendo su crecimiento a los pagos de Ayacucho, Tandil y Lobería.Fue con la administración de Elias que se construye la casa principal, desarrollada en una planta con forma de L, dando lugar al patio con el aljibe. La galería con delgadas columnas de hierro fundido, con desagüe pluvial incorporado, y en una de los extremos se distingue la matera de forma circular. Se distinguen así tres sectores: casco principal, área de servicios y el galpón de toros, vinculadas por dos importantes arcos, formando una imponente fachada.El galpón de toros  surgió con la cría de ganado Shorton, cuenta con un entrepiso para guardar lana, cueros y elementos de trabajo. Se conservan aun las argollas donde se sujetaba el ganado.`,
    },
    {
      id: 5,
      imgSrc: historia5,
      imgAlt: "Federico y Ceferino Girado en la administración de la estancia",
      orderLast: true,
      year: 1870,
      description: `Lo siguen en su administración dos de sus hijos, Federico y Ceferino, hasta 1870, momento en que fallece Federico, quedando Ceferino a cargo, continuando el desarrollo de la cría de ganado Shorton principalmente. De la mano de las subdivisiones obligadas por la numerosa descendencia de esta rama de los Girado, la estancia deja de pertenecer a la familia, y el casco principal e instalaciones fueron deteriorándose y, finalmente el predio que antaño fuera motor del progreso de la zona va quedando en el abandono.`,
    },
    {
      id: 6,
      imgSrc: historia6,
      imgAlt: "La Alameda declarada patrimonio histórico en 1970",
      orderLast: false,
      year: 1970,
      description: `Hacia 1970, el Estado declaró a La Alameda patrimonio histórico y cultural.`,
    },
    {
      id: 7,
      imgSrc: historia7,
      imgAlt: "Municipio adquiriendo La Alameda en 1984",
      orderLast: true,
      year: 1984,
      description: `En 1984, el municipio con el fin de preservarla, consigue adquirirla y comienza a concesionarla. Los sucesivos adjudicatarios, hacían incapié en el parque e inmediaciones de la laguna, dejando de lado la conservación del histórico casco.`,
    },
    {
      id: 8,
      imgSrc: historia8,
      imgAlt:
        "Restauración de La Alameda por Gustavo y Alejandro Cánepa en 2003",
      orderLast: false,
      year: 2003,
      description: `Recién en el año 2003 Gustavo y Alejandro Cánepa, deciden iniciar un proyecto turístico que contempla la recuperación integral del casco principal y del resto de las instalaciones. Luego de un año de restauración, respetando las viejas técnicas de construcción y el estilo colonial de la época, se recuperaron revoques, aberturas, se restauró el piso y se pintaron las paredes a cal con los colores emblemáticos. Además, se reconstruyó el corral de toros, que había sido destruido por un árbol, y se integró de manera armónica un nuevo salón, que funciona actualmente para eventos sociales y corporativos, y salón comedor para los días de campo que se desarrollan en la estancia. El casco principal reacondicionado con detalles de confort y modernidad, es donde hoy funciona el Hotel Boutique de la estancia, con sus salas y galerías originales. Con este proyecto se dio inicio al turismo internacional en la ciudad de Chascomús, recibiendo de forma regular turistas de todo el mundo, tanto en forma individual como grupales, a través de grupos de incentivos. Incorporando para tal fin un ballet folclórico de la estancia estable y artesanos de la zona que daban a conocer y ofrecían sus productos. `,
    },
    {
      id: 9,
      imgSrc: historia9,
      imgAlt: "Turistas disfrutando de la estancia La Alameda en la actualidad",
      orderLast: true,
      year: currentYear,
      description: `Hoy en día nos visitan turistas tanto locales como extranjeros, que vienen a disfrutar  de días de campo y/o de estadías en nuestro Hotel Boutique. También realizamos eventos sociales como bodas, cumpleaños, bautismos, aniversarios; evento corporativos de fin de año, jornadas de trabajo, recreativos; producciones fotográficas, cinematográficas, entre ellas se destaca la película “Zama” de Lucrecia Martel, multipremiada, que fue rodada en la estancia.`,
    },
  ];

  return (
    <>
      <section className="bg-secondary">
        <ScrollToTop />
        <Banner
          title={"Nuestra Historia"}
          bgImage={"bg-history"}
          bgOpacity={"60"}
        />
        <div className="flex flex-col px-8 mt-10 mx-auto space-y-12 max-w-7xl xl:px-12">
          {historysData.map((history) => (
            <div
              key={history.id}
              data-aos={history.orderLast ? "fade-left" : "fade-right"}
              className={`flex flex-col mb-8 ${
                history.orderLast ? "sm:flex-row-reverse" : "sm:flex-row"
              } animated fadeIn`}
            >
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                <img
                  className="shadow-xl"
                  src={history.imgSrc}
                  alt={history.imgAlt}
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                <h3 className="font-bold mt-2 text-2xl sm:text-left md:text-4xl">
                  {history.year}
                </h3>

                <p className="mt-5 text-lg text-gray-700 text md:text-left mr-8">
                  {expandedSection === history.id
                    ? history.description
                    : history.description.length > 300
                    ? `${history.description.substring(0, 300)}...`
                    : history.description}
                </p>
                {history.description.length > 300 && (
                  <button
                    className="mt-4 text-blue-500"
                    onClick={() => handleExpand(history.id)}
                  >
                    {expandedSection === history.id ? "Ver menos" : "Ver más"}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default HistoryPage;
