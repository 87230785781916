import React, { createContext, useEffect, useState } from "react";
import { formatDate } from "../utils/formatDate";
import { roomData } from "../data";

export const RoomContext = createContext();

const PRICE_WEKEEKEN_GLAMPING = 110000;
const RATIO_WEKEEKEN_GLAMPING = 70000;

const RoomProvider = ({ children }) => {
  const [rooms, setRooms] = useState(roomData);
  const [adults, setAdults] = useState("2 Adultos");
  const [kids, setKids] = useState("0 Niños");
  const [typeRoom, setTypeRoom] = useState("Hotel");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [daysBetween, setDaysBetween] = useState(0);
  const [weekdaysCount, setWeekdaysCount] = useState(0); // Días de semana
  const [weekendCount, setWeekendCount] = useState(0); // Fines de semana

  useEffect(() => {
    const totalAdults = Number(adults[0]);
    const totalKids = Number(kids[0]);
    setTotal(totalAdults + totalKids);
  }, [adults, kids]);

  // Función para calcular la diferencia en días y contar los días de semana y fines de semana
  useEffect(() => {
    if (checkInDate && checkOutDate) {
      const startDate = new Date(checkInDate);
      const endDate = new Date(checkOutDate);
      const differenceInTime = endDate - startDate;

      // Calcular noches restando 1
      const differenceInNights = Math.ceil(
        differenceInTime / (1000 * 3600 * 24)
      );
      setDaysBetween(differenceInNights);

      // Contar cuántos días de semana y cuántos fines de semana hay entre las dos fechas
      let weekdays = 0;
      let weekends = 0;
      let currentDate = new Date(startDate);

      for (let i = 0; i < differenceInNights; i++) {
        const dayOfWeek = currentDate.getDay(); // 0 = Domingo, 1 = Lunes, ..., 6 = Sábado
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          weekdays++; // Lunes a jueves
        } else {
          weekends++; // Viernes a domingo
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setWeekdaysCount(weekdays);
      setWeekendCount(weekends);
    }
  }, [checkInDate, checkOutDate]);

  // Función para calcular el precio sin descuento (precio completo)
  const calculateFullPrice = (
    basePrice,
    priceDifference,
    days,
    totalAdults,
    totalKids,
    typeRoom
  ) => {
    if (days <= 0) return 0;

    let weekPrice = 0;
    let weekendPrice = 0;

    // Precio para días de semana (sin descuento)
    if (weekdaysCount > 0) {
      const priceBaseWeek = basePrice + (weekdaysCount - 1) * priceDifference;
      weekPrice = priceBaseWeek * totalAdults + priceBaseWeek * 0.5 * totalKids;
    }

    // Precio para fines de semana (sin descuento)
    if (weekendCount > 0) {
      const priceBaseWeekend = basePrice + (weekendCount - 1) * priceDifference;

      if (typeRoom === "Glamping") {
        const priceBaseWeekendGlamping =
          PRICE_WEKEEKEN_GLAMPING +
          (weekendCount - 1) * RATIO_WEKEEKEN_GLAMPING;
        weekendPrice = priceBaseWeekendGlamping * totalAdults;
      } else {
        weekendPrice =
          priceBaseWeekend * totalAdults + priceBaseWeekend * 0.5 * totalKids;
      }
    }

    return weekPrice + weekendPrice;
  };

  // Función para calcular el precio con descuento
  const calculatePrice = (
    basePrice,
    priceDifference,
    days,
    totalAdults,
    totalKids,
    typeRoom,
    weekdayPromotion
  ) => {
    if (days <= 0) return 0;

    let weekPrice = 0;
    let weekendPrice = 0;

    // Precio para días de semana con descuento
    if (weekdaysCount > 0) {
      const priceBaseWeek = basePrice + (weekdaysCount - 1) * priceDifference;
      const discountedPrice = priceBaseWeek * (1 - weekdayPromotion); // Aplicar descuento del 40%

      weekPrice =
        discountedPrice * totalAdults + discountedPrice * 0.5 * totalKids;
    }

    // Precio para fines de semana (sin descuento)
    if (weekendCount > 0) {
      const priceBaseWeekend = basePrice + (weekendCount - 1) * priceDifference;

      if (typeRoom === "Glamping") {
        const priceBaseWeekendGlamping =
          PRICE_WEKEEKEN_GLAMPING +
          (weekendCount - 1) * RATIO_WEKEEKEN_GLAMPING;
        weekendPrice = priceBaseWeekendGlamping * totalAdults;
      } else {
        weekendPrice =
          priceBaseWeekend * totalAdults + priceBaseWeekend * 0.5 * totalKids;
      }
    }

    return weekPrice + weekendPrice;
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    const totalAdults = Number(adults[0]);
    const totalKids = Number(kids[0]);

    const newRooms = roomData
      .filter((room) => {
        if (Array.isArray(room.maxPerson)) {
          return room.maxPerson.includes(total) && room.typeRoom === typeRoom;
        }
        return total === room.maxPerson && room.typeRoom === typeRoom;
      })
      .map((room) => {
        let warningMessage = "";

        if (room.minTwoNightsWeekend && weekendCount > 0 && daysBetween < 2) {
          warningMessage = "Mínimo 2 noches los fines de semana";
        }

        // Precio con descuento
        const pricePerNight = calculatePrice(
          room.price,
          room.priceDifference,
          daysBetween,
          totalAdults,
          totalKids,
          room.typeRoom,
          room.weekdayPromotion
        );

        // Precio sin descuento
        const fullPrice = calculateFullPrice(
          room.price,
          room.priceDifference,
          daysBetween,
          totalAdults,
          totalKids,
          room.typeRoom
        );

        return {
          ...room,
          totalAdults,
          totalKids,
          typeRoom,
          checkInDate: formatDate(checkInDate),
          checkOutDate: formatDate(checkOutDate),
          daysBetween,
          weekdaysCount,
          weekendCount,
          totalPrice: pricePerNight,
          fullPrice, // Precio sin descuento
          warningMessage,
        };
      })
      .filter((room) => room !== null);

    setTimeout(() => {
      setRooms(newRooms);
      setLoading(false);
    }, 3000);
  };

  return (
    <RoomContext.Provider
      value={{
        rooms,
        adults,
        setAdults,
        kids,
        setKids,
        handleClick,
        loading,
        typeRoom,
        setTypeRoom,
        checkInDate,
        setCheckInDate,
        checkOutDate,
        setCheckOutDate,
        daysBetween,
        weekdaysCount,
        weekendCount,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};

export default RoomProvider;
