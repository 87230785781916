import React, { useState } from "react";
import { IoIosPlay, IoMdClose } from "react-icons/io";

function Movie() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlayClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col w-full h-[500px] bg-cover bg-fixed bg-center justify-center items-center relative">
      <div className="absolute inset-0 w-full h-full bg-cover bg-fixed bg-center bg-movie"></div>
      <div className="absolute w-full h-full bg-black/50"></div>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
        <div className="text-center text-white">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Hotel Boutique
          </div>
          <h2 className="font-primary text-[45px] mb-4">Estancia La Alameda</h2>
        </div>
        <button
          onClick={handlePlayClick}
          aria-label="Reproducir video"
          className="w-20 h-20 rounded-full border flex justify-center items-center bg-black/20 text-white hover:bg-black/40 hover:scale-110 transition-transform duration-300 ease-in-out"
        >
          <IoIosPlay className="text-4xl" />
        </button>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="relative w-full max-w-4xl">
              <button
                onClick={handleCloseModal}
                aria-label="Cerrar video"
                className="absolute -top-10 right-0 text-white rounded-full h-8 w-8"
              >
                <IoMdClose className="text-2xl hover:opacity-70" />
              </button>
              <iframe
                width="99%"
                height="500px"
                src="https://www.youtube.com/embed/-UWugXzBeuY?si=NoOWlqgO9HTFAYGp"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Movie;
