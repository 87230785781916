import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css"; // Importa los estilos de Swiper
import { Pagination, EffectFade, Autoplay } from "swiper";

import welcome1 from "../../assets/img/welcome/welcome1.jpg";
import welcome2 from "../../assets/img/welcome/welcome2.jpg";
import welcome3 from "../../assets/img/welcome/welcome3.jpg";
import welcome4 from "../../assets/img/welcome/welcome4.jpg";
import { Link } from "react-router-dom";

const dataWelcome = [
  {
    id: 1,
    img: welcome1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: welcome2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: welcome3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: welcome4,
    alt: "Estancia La Alameda",
  },
];

function Welcome() {
  return (
    <section className="bg-secondary min-h-[640px] mx-auto flex items-center py-16">
      <div className="container max-w-[1200px] mx-auto grid md:grid-cols-2 gap-14">
        <div className="flex flex-col justify-center items-start">
          <h3 className="text-coyote font-normal opacity-70 text-[15px] tracking-[6px] font-tertiary uppercase">
            Bienvenidos
          </h3>
          <h1 className="font-primary text-2xl sm:text-[38px] tracking-[.04em] font-normal py-3 text-black mb-3">
            Estancia La Alameda
          </h1>
          <p className="opacity-60 font-light font-secondary text-justify text-base sm:text-lg text-black">
            Exclusivo hotel boutique ubicado en Chascomús, ideal para disfrutar
            de la tranquilidad y la naturaleza. Con una historia rica y un
            entorno natural privilegiado, ofrece una experiencia única para
            quienes buscan relajarse en un ambiente campestre. Además, cuenta
            con actividades al aire libre, un restaurante con platos
            tradicionales y espacios para eventos especiales, haciendo de cada
            visita una experiencia memorable.
          </p>

          <Link to={"/historia"}>
            <button className="btn btn-lg btn-primary w-full mt-4">
              Nuestra Historia
            </button>
          </Link>
        </div>

        <div className="welcome-slider overflow-hidden">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            modules={[EffectFade, Autoplay, Pagination]} // Habilita módulos opcionales
            effect={"fade"}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
            }}
          >
            {dataWelcome.map((data) => (
              <SwiperSlide key={data.id}>
                <div className="h-[425px]">
                  <img
                    className="w-full h-full object-cover"
                    src={data.img}
                    alt={data.alt}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
