import React from "react";
import GenerateWhatsAppLink from "../GenerateWhatsAppLink";

function Banner({ title, bgImage, bgOpacity, button, message, buttonClass }) {
  return (
    <div
      className={`${
        bgImage ? bgImage : "bg-room"
      } bg-cover bg-center h-[560px] relative flex justify-center items-center`}
    >
      <div
        className={`absolute w-full h-full bg-black/${
          bgOpacity ? bgOpacity : "70"
        }`}
      ></div>
      <div className="z-20">
        <h2 className="text-6xl text-white z-20 font-primary text-center">
          {title}
        </h2>
        {button && (
          <GenerateWhatsAppLink
            nameButton={button}
            message={message}
            buttonClass={buttonClass}
          />
        )}
      </div>
    </div>
  );
}

export default Banner;
