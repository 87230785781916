import React, { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Banner from "../../components/Banner";
import GenerateWhatsAppLink from "../../components/GenerateWhatsAppLink";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper"; // Agregar Navigation y Autoplay
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import FSLightbox from "fslightbox-react"; // Importa FSLightbox

import menu1 from "../../assets/img/services/restaurant/menu1.jpg";
import menu2 from "../../assets/img/services/restaurant/menu2.jpg";
import menu3 from "../../assets/img/services/restaurant/menu3.jpg";
import menu4 from "../../assets/img/services/restaurant/menu4.jpg";
import menu5 from "../../assets/img/services/restaurant/menu5.jpg";
import menu6 from "../../assets/img/services/restaurant/menu6.jpg";
import menu7 from "../../assets/img/services/restaurant/menu7.jpg";
import menu8 from "../../assets/img/services/restaurant/menu8.jpg";
import menu9 from "../../assets/img/services/restaurant/menu9.jpg";
import menu10 from "../../assets/img/services/restaurant/menu10.jpg";

import matera1 from "../../assets/img/services/restaurant/matera1.jpg";
import matera2 from "../../assets/img/services/restaurant/matera2.jpg";
import matera3 from "../../assets/img/services/restaurant/matera3.jpg";
import matera4 from "../../assets/img/services/restaurant/matera4.jpg";
import matera5 from "../../assets/img/services/restaurant/matera5.jpg";
import matera6 from "../../assets/img/services/restaurant/matera6.jpg";
import matera7 from "../../assets/img/services/restaurant/matera7.jpg";
import matera8 from "../../assets/img/services/restaurant/matera8.jpg";

const dataWelcome = [
  {
    id: 1,
    img: menu1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: menu2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: menu3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: menu4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: menu5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: menu6,
    alt: "Estancia La Alameda",
  },
  {
    id: 7,
    img: menu7,
    alt: "Estancia La Alameda",
  },
  {
    id: 8,
    img: menu8,
    alt: "Estancia La Alameda",
  },
  {
    id: 9,
    img: menu9,
    alt: "Estancia La Alameda",
  },
  {
    id: 10,
    img: menu10,
    alt: "Estancia La Alameda",
  },
];

const materaImg = [
  {
    id: 1,
    img: matera1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: matera2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: matera3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: matera4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: matera5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: matera6,
    alt: "Estancia La Alameda",
  },
  {
    id: 7,
    img: matera7,
    alt: "Estancia La Alameda",
  },
  {
    id: 8,
    img: matera8,
    alt: "Estancia La Alameda",
  },
];

function RestaurantPage() {
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleImageClick = (images, index) => {
    setSelectedImages(images); // Almacena las imágenes seleccionadas
    setPhotoIndex(index); // Establece el índice de la imagen activa
    setLightboxToggler(!lightboxToggler); // Abre el lightbox
  };

  const openPDF = () => {
    // Ruta del archivo PDF en el proyecto
    window.open("/Carta-LaMatera.pdf", "_blank");
  };
  return (
    <section>
      <ScrollToTop />
      <Banner
        title={"La Matera"}
        bgImage={"bg-restaurant"}
        bgOpacity={"50"}
        button={"Realizar Reserva"}
        message={`Hola, me gustaría hacer una reserva en el restaurante la matera.`}
        buttonClass={"mx-auto"}
      />

      <div className="container mx-auto px-4 pt-8 sm:pt-0">
        {/* Información del Restaurante */}
        <section className="sm:mt-10">
          <h2 className="text-3xl text-gray-800 mb-4 font-primary">
            Restaurante La Matera
          </h2>
          <section className="pb-4">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              slidesPerView={1} // Por defecto, se muestran 3 diapositivas
              spaceBetween={30} // Espacio de 30px entre diapositivas
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000, // Cambia de imagen cada 3 segundos
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }} // Paginación habilitada
              breakpoints={{
                // Cuando el ancho es menor a 640px, muestra solo 1 diapositiva
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // Cuando el ancho es menor a 768px, muestra 2 diapositivas
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // Para pantallas más grandes (mayores a 1024px), muestra 3 diapositivas
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              {materaImg.map((data, index) => (
                <SwiperSlide key={data.id}>
                  <div className="h-[425px]">
                    <img
                      className="w-full h-full object-cover cursor-pointer"
                      src={data.img}
                      alt={data.alt}
                      onClick={() =>
                        handleImageClick(
                          materaImg.map((img) => img.img),
                          index
                        )
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          <p className="text-gray-600 mb-6 font-primary">
            Ubicado en el comedor original de la casa, La Matera es un espacio
            donde lo auténtico cobra vida. Abierto a todo el público, nuestro
            restaurante ofrece lo mejor de la cocina argentina tradicional, con
            platos caseros que despiertan los sentidos y llenan el alma.
          </p>
          <p className="text-gray-600 mb-6 font-primary">
            Cada comida es una experiencia única, acompañada por una vista
            imponente a la Laguna, un regalo visual que te conecta con la
            belleza natural de nuestro entorno.
          </p>
          <p className="text-gray-600 mb-6 font-primary">
            Te invitamos a disfrutar de la calidez de nuestros sabores y la
            serenidad del paisaje. La Matera es más que un restaurante; es un
            lugar donde el tiempo parece detenerse, y los recuerdos se crean en
            cada bocado.
          </p>
        </section>

        {/* Menu de Imágenes */}
        <section className="border-t border-gray-300 mt-6 pt-4">
          <h2 className="text-3xl font-primary text-gray-800 mb-4">
            Nuestros Platos
          </h2>
          <p className="text-gray-700 font-secondary mb-4">
            En nuestro restaurante, te ofrecemos una selección variada de platos
            que reflejan lo mejor de la cocina argentina. Desde entradas
            exquisitas hasta postres irresistibles, cada plato está preparado
            con los ingredientes más frescos y un toque especial. ¡Descubre lo
            que tenemos para ti!
          </p>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            slidesPerView={1} // Por defecto, se muestran 1 diapositiva
            spaceBetween={30} // Espacio de 30px entre diapositivas
            navigation={true}
            loop={true}
            autoplay={{
              delay: 3000, // Cambia de imagen cada 3 segundos
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }} // Paginación habilitada
            breakpoints={{
              // Cuando el ancho es menor a 640px, muestra solo 1 diapositiva
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // Cuando el ancho es menor a 768px, muestra 2 diapositivas
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // Para pantallas más grandes (mayores a 1024px), muestra 3 diapositivas
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper mb-4"
          >
            {dataWelcome.map((data, index) => (
              <SwiperSlide key={data.id}>
                <div className="h-[425px]">
                  <img
                    className="w-full h-full object-cover cursor-pointer"
                    src={data.img}
                    alt={data.alt}
                    onClick={() =>
                      handleImageClick(
                        dataWelcome.map((img) => img.img),
                        data.id - index
                      )
                    } // Pasa todas las imágenes al lightbox
                  />

                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                    <h3 className="text-white text-lg font-bold">
                      {data.title}
                    </h3>
                    <p className="text-white">{data.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Botón para abrir el menú PDF */}
          <button onClick={openPDF} className="btn btn-lg btn-primary mt-4">
            Explora Nuestra Carta
          </button>
        </section>

        {/* Horarios de Apertura */}
        <section className="my-8">
          <h2 className="text-3xl font-primary mb-4 text-gray-800">
            Horarios de Apertura
          </h2>
          <p className="text-gray-700 font-secondary mb-4">
            Nuestro restaurante abre sus puertas para que disfrutes de la
            auténtica cocina argentina en un ambiente acogedor y único. A
            continuación, te compartimos los horarios en los que puedes
            visitarnos y vivir esta experiencia inolvidable:
          </p>
          <ul className="text-gray-600 font-secondary list-disc ml-6 mb-6">
            <li>
              Sábado: <span className="font-bold">12:00 PM</span> - Ven a
              disfrutar de un almuerzo relajado con familia y amigos.
            </li>
            <li>
              Domingo: <span className="font-bold">12:00 PM</span> - Perfecto
              para un asado en un entorno campestre.
            </li>
            <li>
              Feriados: <span className="font-bold">12:00 PM</span> - Porque en
              los días festivos siempre es buen momento para celebrar con buena
              comida.
            </li>
          </ul>
          <p className="text-gray-700 font-secondary mb-4">
            Te recomendamos realizar tu reserva con anticipación para garantizar
            tu lugar y asegurarte una experiencia única. Haz clic en el botón a
            continuación para reservar de forma rápida y sencilla a través de
            WhatsApp:
          </p>

          <GenerateWhatsAppLink
            nameButton={"Realizar Reserva"}
            message={`Hola, me gustaría hacer una reserva en el restaurante la matera.`}
          />
        </section>
      </div>
      {/* Lightbox */}
      <FSLightbox
        toggler={lightboxToggler}
        sources={selectedImages}
        slide={photoIndex + 1} // Establecer la imagen activa
      />
    </section>
  );
}

export default RestaurantPage;
