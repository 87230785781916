import React from "react";
// link
import { Link } from "react-router-dom";
// icons
import { BsArrowsFullscreen, BsPeople } from "react-icons/bs";
import { FaTag } from "react-icons/fa";
import { formatPrice } from "../../utils/formatPrice";

const Room = ({ room }) => {
  // destructure room
  const {
    id,
    name,
    imageCard,
    size,
    maxPerson,
    description,
    price,
    totalPrice,
    fullPrice, // Agregado para mostrar el precio sin descuento
    warningMessage,
    weekdayPromotion,
    weekdaysCount,
  } = room;

  return (
    <div className="bg-white shadow-2xl min-h-[500px] group relative">
      {/* img */}
      <div className="relative w-full overflow-hidden">
        <img
          className="group-hover:scale-110 transition-all duration-300 w-full"
          src={imageCard}
          alt={name}
        />
        {/* Badge de descuento */}
        {weekdaysCount > 0 && weekdayPromotion !== 0 && (
          <div className="absolute top-3 right-3 bg-red-600 text-white text-xs font-bold px-3 py-2 rounded-full flex items-center gap-1">
            <FaTag className="text-white text-sm" /> {/* Icono */}
            {weekdayPromotion * 100}% {/* Descuento */}
          </div>
        )}
      </div>
      {/* details */}
      <div className="bg-white shadow-lg max-w-[300px] mx-auto h-[60px] -translate-y-1/2 flex justify-center items-center uppercase font-tertiary tracking-[1px] font-semibold text-base">
        <div className="flex justify-between w-[80%]">
          {/* size */}
          <div className="flex items-center gap-x-2">
            <div className="text-accent">
              <BsArrowsFullscreen className="text-[15px]" />
            </div>
            <div className="flex gap-x-1">
              <div>Size</div>
              <div>{size}m²</div>
            </div>
          </div>
          {/* room capacity */}
          <div className="flex items-center gap-x-2">
            <div className="text-accent">
              <BsPeople className="text-[18px]" />
            </div>
            <div className="flex gap-x-1">
              <div>Capacidad</div>
              <div>
                {Array.isArray(maxPerson) ? maxPerson.join("/") : maxPerson}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* name & description */}
      <div className="text-center">
        <Link to={`/room/${id}`}>
          <h3 className="h3">{name}</h3>
        </Link>
        <p className="max-w-[300px] mx-auto mb-3 lg:mb-6">
          {description.slice(0, 56) + "..."}
        </p>
      </div>
      {/* warning message */}
      {warningMessage ? (
        <p className="btn btn-secondary bg-accent btn-sm max-w-[280px] mx-auto text-center">
          {warningMessage}
        </p>
      ) : (
        <Link
          to={`/room/${id}`}
          className="btn btn-secondary btn-sm max-w-[280px] mx-auto flex flex-col items-center"
        >
          {/* Mostrar el precio antes si hay descuento */}
          {weekdaysCount > 0 &&
            weekdayPromotion !== 0 &&
            totalPrice &&
            price !== totalPrice && (
              <div className="text-gray-500 line-through">
                Antes: ${formatPrice(fullPrice)} {/* Precio sin descuento */}
              </div>
            )}
          {/* Mostrar el precio con descuento */}
          {totalPrice
            ? `Reservar por $${formatPrice(totalPrice)}`
            : `Reserva desde $ ${formatPrice(price)}`}
        </Link>
      )}
    </div>
  );
};

export default Room;
