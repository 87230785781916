import React from "react";
import Banner from "../../components/Banner";
import ScrollToTop from "../../components/ScrollToTop";
import BookForm from "../../components/BookForm";
import Rooms from "../../components/Rooms";

function RoomsPage() {
  return (
    <section>
      <ScrollToTop />
      <Banner title={"Habitaciones"} bgImage={"bg-hotel"} bgOpacity={"50"} />
      <div className="container mx-auto relative">
        <div className="bg-accent/20 mt-4 p-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12">
          <BookForm />
        </div>
      </div>
      <div className="py-10">
        <Rooms />
      </div>
    </section>
  );
}

export default RoomsPage;
