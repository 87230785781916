// galleryImages.js

import Gallery1 from "../../assets/img/gallery/gallery1.jpg";
import Gallery2 from "../../assets/img/gallery/gallery2.jpg";
import Gallery3 from "../../assets/img/gallery/gallery3.jpg";
import Gallery4 from "../../assets/img/gallery/gallery4.jpg";
import Gallery5 from "../../assets/img/gallery/gallery5.jpg";
import Gallery6 from "../../assets/img/gallery/gallery6.jpg";
import Gallery7 from "../../assets/img/gallery/gallery7.jpg";
import Gallery8 from "../../assets/img/gallery/gallery8.jpg";
import Gallery9 from "../../assets/img/gallery/gallery9.jpg";
import Gallery10 from "../../assets/img/gallery/gallery10.jpg";
import Gallery11 from "../../assets/img/gallery/gallery11.jpg";
import Gallery12 from "../../assets/img/gallery/gallery12.jpg";
import Gallery13 from "../../assets/img/gallery/gallery13.jpg";
import Gallery14 from "../../assets/img/gallery/gallery14.jpg";
import Gallery15 from "../../assets/img/gallery/gallery15.jpg";
import Gallery16 from "../../assets/img/gallery/gallery16.jpg";
import Gallery17 from "../../assets/img/gallery/gallery17.jpg";
import Gallery18 from "../../assets/img/gallery/gallery18.jpg";
import Gallery19 from "../../assets/img/gallery/gallery19.jpg";
import Gallery20 from "../../assets/img/gallery/gallery20.jpg";
import Gallery21 from "../../assets/img/gallery/gallery21.jpg";
import Gallery22 from "../../assets/img/gallery/gallery22.jpg";
import Gallery23 from "../../assets/img/gallery/gallery23.jpg";
import Gallery24 from "../../assets/img/gallery/gallery24.jpg";
import Gallery25 from "../../assets/img/gallery/gallery25.jpg";
import Gallery26 from "../../assets/img/gallery/gallery26.jpg";
import Gallery27 from "../../assets/img/gallery/gallery27.jpg";
import Gallery28 from "../../assets/img/gallery/gallery28.jpg";
import Gallery29 from "../../assets/img/gallery/gallery29.jpg";
import Gallery30 from "../../assets/img/gallery/gallery30.jpg";
import Gallery31 from "../../assets/img/gallery/gallery31.jpg";
import Gallery32 from "../../assets/img/gallery/gallery32.jpg";
import Gallery33 from "../../assets/img/gallery/gallery33.jpg";
import Gallery34 from "../../assets/img/gallery/gallery34.jpg";
import Gallery35 from "../../assets/img/gallery/gallery35.jpg";
import Gallery36 from "../../assets/img/gallery/gallery36.jpg";
import Gallery37 from "../../assets/img/gallery/gallery37.jpg";
import Gallery38 from "../../assets/img/gallery/gallery38.jpg";
import Gallery39 from "../../assets/img/gallery/gallery39.jpg";
import Gallery40 from "../../assets/img/gallery/gallery40.jpg";
import Gallery41 from "../../assets/img/gallery/gallery41.jpg";
import Gallery42 from "../../assets/img/gallery/gallery42.jpg";
import Gallery43 from "../../assets/img/gallery/gallery43.jpg";
import Gallery44 from "../../assets/img/gallery/gallery44.jpg";
import Gallery45 from "../../assets/img/gallery/gallery45.jpg";
import Gallery46 from "../../assets/img/gallery/gallery46.jpg";
import Gallery47 from "../../assets/img/gallery/gallery47.jpg";
import Gallery48 from "../../assets/img/gallery/gallery48.jpg";
import Gallery49 from "../../assets/img/gallery/gallery49.jpg";
import Gallery50 from "../../assets/img/gallery/gallery50.jpg";
import Gallery51 from "../../assets/img/gallery/gallery51.jpg";
import Gallery52 from "../../assets/img/gallery/gallery52.jpg";
import Gallery53 from "../../assets/img/gallery/gallery53.jpg";
import Gallery54 from "../../assets/img/gallery/gallery54.jpg";
import Gallery55 from "../../assets/img/gallery/gallery55.jpg";

const images = [
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Gallery8,
  Gallery9,
  Gallery10,
  Gallery11,
  Gallery12,
  Gallery13,
  Gallery14,
  Gallery15,
  Gallery16,
  Gallery17,
  Gallery18,
  Gallery19,
  Gallery20,
  Gallery21,
  Gallery22,
  Gallery23,
  Gallery24,
  Gallery25,
  Gallery26,
  Gallery27,
  Gallery28,
  Gallery29,
  Gallery30,
  Gallery31,
  Gallery32,
  Gallery33,
  Gallery34,
  Gallery35,
  Gallery36,
  Gallery37,
  Gallery38,
  Gallery39,
  Gallery40,
  Gallery41,
  Gallery42,
  Gallery43,
  Gallery44,
  Gallery45,
  Gallery46,
  Gallery47,
  Gallery48,
  Gallery49,
  Gallery50,
  Gallery51,
  Gallery52,
  Gallery53,
  Gallery54,
  Gallery55,
];

export default images;
