import React, { useContext, useEffect } from "react";
// context
import { RoomContext } from "../../context/RoomContext";
// components
import Room from "../../components/Room";
// loader
import { SpinnerDotted } from "spinners-react";

import AOS from "aos";
import "aos/dist/aos.css";

const Rooms = () => {
  const { rooms, loading } = useContext(RoomContext);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Configurar duración de las animaciones
  }, []);

  return (
    <section id="rooms" className="py-10">
      {/* overlay & spinner */}
      {loading && (
        <div className="h-screen fixed bottom-0 top-0 bg-black/90 w-full z-50 flex justify-center items-center">
          <SpinnerDotted color="white" />
        </div>
      )}

      {!loading && (
        <div className="container mx-auto lg:px-0">
          <div className="text-center">
            <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
              Hotel Boutique
            </div>
            <h2 className="font-primary text-[45px] mb-4">Nuestras Suites</h2>
          </div>

          {/* Verificar si hay habitaciones */}
          {rooms.length === 0 ? (
            <div className="text-center mt-10">
              <p className="text-2xl font-semibold">
                No se encontraron habitaciones disponibles.
              </p>
              <p className="text-lg mt-4">
                Por favor, vuelve a intentarlo más tarde o contáctanos para más
                información.
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 max-w-sm mx-auto gap-[30px] md:grid-cols-2 lg:grid-cols-3 md:max-w-none lg:max-w-none lg:mx-0">
              {rooms
                .filter((room) => room.typeRoom === "Hotel")
                .map((room, index) => {
                  return (
                    <div
                      key={room.id}
                      data-aos="fade-up"
                      data-aos-delay={index * 100}
                      data-aos-duration="800"
                    >
                      <Room room={room} key={room.id} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Rooms;
