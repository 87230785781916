import React from "react";
// components
import Rooms from "../../components/Rooms";
import BookForm from "../../components/BookForm";
import HeroSlider from "../../components/HeroSlider";
import Gallery from "../../components/Gallery";
import Welcome from "../../components/Welcome";
import Services from "../../components/Services";
import Movie from "../../components/Movie";
import View360 from "../../components/View360";
import Location from "../../components/Location";
import ServicesHotel from "../../components/ServicesHotel";

const HomePage = () => {
  return (
    <>
      <HeroSlider />
      <div className="container mx-auto relative">
        <div className="bg-accent/20 mt-4 p-4 mb-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12">
          <BookForm />
        </div>
      </div>
      <Welcome />
      <Rooms />
      <View360 bg={"bg-secondary"} />
      <Movie />
      <ServicesHotel />
      <Services />
      <Gallery />
      <Location />
    </>
  );
};

export default HomePage;
