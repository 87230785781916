import React from "react";
import diaDeCampo from "../../../src/assets/img/services/diaDeCampo/diaDeCampo.jpg";
import restaurant from "../../../src/assets/img/services/restaurant/laMatera.jpg";
import eventos from "../../../src/assets/img/services/eventos/eventos.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";

const services = [
  {
    img: diaDeCampo,
    alt: "Vista de la actividad de campo en la estancia",
    title: "Día de Campo",
    to: "/servicios/diaDeCampo",
  },
  {
    img: restaurant,
    alt: "Interior del restaurante La Matera",
    title: "La Matera - Restaurante",
    to: "/servicios/restaurante",
  },
  {
    img: eventos,
    alt: "Preparativos para eventos en la estancia",
    title: "Eventos",
    to: "/servicios/eventos",
  },
];

function ServicesHotel() {
  return (
    <section id="service" className="py-10 px-3">
      <ScrollToTop />
      <div className="container max-w-[1200px] mx-auto">
        <div className="text-center">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Hotel Boutique
          </div>
          <h2 className="font-primary text-[45px] mb-4">Nuestros servicios</h2>
        </div>

        <div className="grid mt-10 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 lg:gap-10">
          {services.map(({ img, alt, title, to }, index) => (
            <Link
              to={to}
              key={index}
              className="transition duration-300 ease-in-out hover:-translate-y-5"
            >
              <div className="min-h-[400px]">
                <img
                  src={img}
                  className="w-full h-[450px] object-cover"
                  alt={alt}
                />
              </div>
              <div className="bg-secondary text-coyote text-center text-lg sm:text-xl font-primary font-medium tracking-widest uppercase py-6 px-4">
                {title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesHotel;
