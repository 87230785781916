import React from "react";
import LogoWhite from "../../assets/img/logo-white.svg";

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-secondary">
      <div className="py-16 px-3">
        <div className="grid max-w-[1200px] mx-auto gap-8 text-center md:grid-cols-2 md:text-start lg:grid-cols-4">
          {/* Sección de Información del Hotel */}
          <div className="md:me-2 lg:me-3">
            <a
              href="index.html"
              className="text-lion font-primary font-normal text-2xl tracking-[.04em]"
            >
              <img className="w-[160px]" src={LogoWhite} alt="Logo" />
            </a>
            <p className="text-black font-light font-secondary text-base mt-3 max-w-[480px] mx-auto md:ms-0">
              Exclusivo hotel boutique ubicado en Chascomús, ideal para
              disfrutar de la tranquilidad y la naturaleza.
            </p>
          </div>

          {/* Sección de Enlaces Rápidos */}
          <div>
            <h4 className="border-b-2 border-accent inline-block font-primary tracking-[.04em] text-lg text-black capitalize relative after:absolute after:content-[''] after:left-0 after:-bottom-0 after:h-[1px] after:w-full after:bg-coyote pb-1 mb-2">
              Menú Rápido
            </h4>
            <ul>
              <li className="my-2">
                <a
                  href="//servicios/eventos"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Eventos
                </a>
              </li>
              <li className="my-2">
                <a
                  href="/servicios/diaDeCampo"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Restaurante
                </a>
              </li>
              <li className="my-2">
                <a
                  href="/servicios/diaDeCampo"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Día de Campo
                </a>
              </li>
              <li className="my-2">
                <a
                  href="/politicasDeReserva"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Politícas de Reserva
                </a>
              </li>
            </ul>
          </div>

          {/* Sección de Explorar */}
          <div>
            <h4 className="border-b-2 border-accent inline-block font-primary tracking-[.04em] text-lg text-black capitalize relative after:absolute after:content-[''] after:left-0 after:-bottom-0 after:h-[1px] after:w-full after:bg-coyote pb-1 mb-2">
              Explorar
            </h4>
            <ul>
              <li className="my-2">
                <a
                  href="/hotel"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Nuestras Suites
                </a>
              </li>

              <li className="my-2">
                <a
                  href="/glamping"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Glamping
                </a>
              </li>
              <li className="my-2">
                <a
                  href="/galeria"
                  className="capitalize font-secondary font-light text-base text-black hover:text-accent transition duration-300 ease-in-out"
                >
                  Galeria
                </a>
              </li>
            </ul>
          </div>

          {/* Sección de Contacto */}
          <div>
            <h4 className="border-b-2 border-accent inline-block font-primary tracking-[.04em] text-lg text-black capitalize relative after:absolute after:content-[''] after:left-0 after:-bottom-0 after:h-[1px] after:w-full after:bg-coyote pb-1 mb-2">
              Contacto
            </h4>
            <ul>
              <li className="my-2 grid grid-cols-[40px_auto] justify-center md:justify-start">
                <span className="text-black">
                  <FaMapMarkerAlt className="text-accent w-5 h-5" />
                </span>
                <span className="text-accent font-light font-secondary">
                  Camino de Circunvalación KM 12
                </span>
              </li>
              <li className="my-4 grid grid-cols-[40px_auto] justify-center items-center md:justify-start">
                <span className="text-black">
                  <FaPhoneAlt className="text-accent w-5 h-5" />
                </span>
                <span className="text-accent font-light font-secondary">
                  (011) 15-5713-3534
                </span>
              </li>
              <li className="my-2 grid grid-cols-[40px_auto] justify-center md:justify-start">
                <span className="text-black">
                  <FaEnvelope className="text-accent w-5 h-5" />
                </span>
                <span className="text-accent font-light font-secondary">
                  reservas@estancialaalameda.com
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="py-4 text-black font-normal font-secondary uppercase text-center tracking-widest bg-nero-dark">
        <p className="text-sm">&copy; copyright 2024 Estancia La Alameda</p>
      </div>
    </footer>
  );
};

export default Footer;
