import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Alert = ({ message }) => {
  const [showAlert, setShowAlert] = useState(true); // Estado para controlar la visibilidad

  return (
    showAlert && (
      <div className="bg-yellow-200 text-yellow-800 p-4 rounded-lg mb-6 relative">
        <strong>¡Importante!</strong> {message}
        <button
          className="absolute top-1 right-1 text-yellow-800 hover:text-yellow-600"
          onClick={() => setShowAlert(false)} // Cerrar alerta
          aria-label="Cerrar alerta"
        >
          <IoIosCloseCircleOutline className="text-2xl" />
        </button>
      </div>
    )
  );
};

export default Alert;
