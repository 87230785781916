// src/App.js
import React from "react";
// react router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// components
import Header from "./components/Header";
import Footer from "./components/Footer";
// icons
import { FaWhatsapp } from "react-icons/fa";
// pages
import HomePage from "./pages/HomePage/Home";
import RoomsPage from "./pages/RoomsPage";
import RoomDetailsPage from "./pages/RoomDetailsPage";
import GlampingPage from "./pages/GlampingPage";
import HistoryPage from "./pages/HistoryPage";
import GalleryPage from "./pages/GalleryPage";
import Page404 from "./pages/Page404";
import FieldDayPage from "./pages/FieldDayPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import ReservationPolicies from "./pages/ReservationPolicies";
import RestaurantPage from "./pages/RestaurantPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/hotel",
    element: <RoomsPage />,
  },
  {
    path: "/room/:id",
    element: <RoomDetailsPage />,
  },
  {
    path: "/glamping",
    element: <GlampingPage />,
  },
  {
    path: "/servicios/diaDeCampo",
    element: <FieldDayPage />,
  },
  {
    path: "/servicios/eventos",
    element: <EventsPage />,
  },
  {
    path: "/servicios/restaurante",
    element: <RestaurantPage />,
  },
  {
    path: "/historia",
    element: <HistoryPage />,
  },
  {
    path: "/galeria",
    element: <GalleryPage />,
  },
  {
    path: "/politicasDeReserva",
    element: <ReservationPolicies />,
  },
  {
    path: "*", // Ruta para manejar URLs no encontradas
    element: <Page404 />,
  },
]);

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <RouterProvider router={router} />
      </main>
      <Footer />
      {/* Botón de WhatsApp */}
      <a
        href="https://api.whatsapp.com/send?phone=5491157133534&text=Hola%20Alameda,%20quisiera%20realizar%20una%20consulta"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-5 right-5 bg-accent text-white p-3 rounded-full shadow-lg hover:bg-accent-hover transition-colors z-50"
        aria-label="Enviar mensaje a través de WhatsApp"
      >
        <FaWhatsapp className="h-10 w-10" />
      </a>
    </div>
  );
};

export default App;
