import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FSLightbox from "fslightbox-react";

import Gallery10 from "../../assets/img/gallery/gallery10.jpg";
import Gallery16 from "../../assets/img/gallery/gallery16.jpg";
import Gallery19 from "../../assets/img/gallery/gallery19.jpg";
import Gallery21 from "../../assets/img/gallery/gallery21.jpg";
import Gallery26 from "../../assets/img/gallery/gallery26.jpg";
import Gallery48 from "../../assets/img/gallery/gallery48.jpg";

// Lista completa de imágenes
const allImages = [
  Gallery10,
  Gallery16,
  Gallery19,
  Gallery21,
  Gallery26,
  Gallery48,
];

function Gallery() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Seleccionar aleatoriamente 6 imágenes sin repetirse
    const shuffledImages = [...allImages].sort(() => Math.random() - 0.5); // Baraja las imágenes
    setImages(shuffledImages.slice(0, 6)); // Toma las primeras 6 imágenes
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index + 1); // FSLightbox es 1-based
    setLightboxToggler(!lightboxToggler);
  };

  return (
    <section className="py-16 px-3 bg-secondary">
      <div className="container max-w-[1200px] mx-auto relative z-10">
        <div className="text-center">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Nuestros espacios
          </div>
          <h2 className="font-primary text-[45px] mb-4">Galería de Imágenes</h2>
        </div>

        <div className="grid gap-8 mt-10 sm:grid-cols-2 md:grid-cols-3">
          {images.map((srcImg, index) => (
            <div
              key={index}
              className="flex text-center flex-col justify-center items-center transition duration-300 ease-in-out"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <img
                src={srcImg}
                alt={`galería-${index}`}
                className="w-full h-64 object-cover object-center transition-transform duration-300 hover:scale-105 cursor-zoom-in"
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-10 text-center">
        <Link
          to="/galeria"
          className="btn btn-secondary btn-sm max-w-[260px] mx-auto"
        >
          Ver más Imágenes
        </Link>
      </div>
      <FSLightbox
        toggler={lightboxToggler}
        sources={images}
        slide={photoIndex}
      />
    </section>
  );
}

export default Gallery;
