import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { BsCalendar } from "react-icons/bs";
import { RoomContext } from "../../context/RoomContext";
import "./style.css";

const DateRangePicker = () => {
  const { checkInDate, setCheckInDate } = useContext(RoomContext);
  const { checkOutDate, setCheckOutDate } = useContext(RoomContext);

  // Función para normalizar fechas a medianoche
  const normalizeDate = (date) => {
    if (!date) return null;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  // Función para bloquear fechas específicas
  const isDateBlocked = (date) => {
    const blockedDates = [
      new Date(2025, 2, 22), // 22 de marzo de 2025
      new Date(2025, 2, 23), // 23 de marzo de 2025
      new Date(2025, 2, 24), // 24 de marzo de 2025
      new Date(2025, 2, 29), // 29 de marzo de 2025

      new Date(2025, 3, 11), // 17 de abril de 2025
      new Date(2025, 3, 12), // 18 de abril de 2025
      new Date(2025, 3, 17), // 17 de abril de 2025
      new Date(2025, 3, 18), // 18 de abril de 2025
      new Date(2025, 3, 19), // 19 de abril de 2025
      new Date(2025, 3, 20), // 19 de abril de 2025
    ];

    return !blockedDates.some(
      (blockedDate) => date.toDateString() === blockedDate.toDateString()
    );
  };

  // Función para manejar el cambio de Check-In
  const handleCheckInChange = (date) => {
    setCheckInDate(normalizeDate(date));

    const dayOfWeek = date.getDay(); // 0 = Domingo, 6 = Sábado

    // Si es viernes, sábado o domingo, forzar 1 noche
    const newCheckOutDate = new Date(date);
    newCheckOutDate.setDate(date.getDate() + 1);
    setCheckOutDate(normalizeDate(newCheckOutDate));
  };

  // Función para manejar el cambio de Check-Out
  const handleCheckOutChange = (date) => {
    if (!checkInDate) return;

    const timeDiff = date - checkInDate;
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    const checkInDayOfWeek = checkInDate.getDay();
    if (
      (checkInDayOfWeek === 0 ||
        checkInDayOfWeek === 5 ||
        checkInDayOfWeek === 6) &&
      diffDays < 1
    ) {
      const forcedCheckOutDate = new Date(checkInDate);
      forcedCheckOutDate.setDate(checkInDate.getDate() + 1);
      setCheckOutDate(normalizeDate(forcedCheckOutDate));
    } else if (diffDays < 1) {
      const forcedCheckOutDate = new Date(checkInDate);
      forcedCheckOutDate.setDate(checkInDate.getDate() + 1);
      setCheckOutDate(normalizeDate(forcedCheckOutDate));
    } else {
      setCheckOutDate(normalizeDate(date));
    }
  };

  return (
    <div className="flex flex-col h-full w-full gap-2 lg:flex-row lg:gap-0">
      {/* Selector de Check-In */}
      <div className="relative flex items-center h-[45px] border-r lg:h-full">
        <div className="absolute left-2">
          <BsCalendar className="text-accent text-base" />
        </div>
        <DatePicker
          className="w-full h-full"
          selected={checkInDate}
          onChange={handleCheckInChange}
          selectsStart
          startDate={checkInDate}
          endDate={checkOutDate}
          minDate={normalizeDate(new Date())} // MinDate sin horas
          filterDate={isDateBlocked}
          dateFormat="dd/MM/yyyy"
          locale={es}
          placeholderText="Check-in"
        />
      </div>

      {/* Selector de Check-Out */}
      <div className="relative flex items-center h-[45px] border-r lg:h-full">
        <div className="absolute left-2">
          <BsCalendar className="text-accent text-base" />
        </div>
        <DatePicker
          className="w-full h-full"
          selected={checkOutDate}
          onChange={handleCheckOutChange}
          selectsEnd
          startDate={checkInDate}
          endDate={checkOutDate}
          minDate={checkInDate || normalizeDate(new Date())} // MinDate sin horas
          filterDate={isDateBlocked}
          dateFormat="dd/MM/yyyy"
          locale={es}
          placeholderText="Check-out"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
