import React, { useState, memo } from "react";
import Banner from "../../components/Banner";
import ScrollToTop from "../../components/ScrollToTop";
import FSLightbox from "fslightbox-react";
import images from "./galleryImages";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GalleryPage = memo(() => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxToggler, setLightboxToggler] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index + 1);
    setLightboxToggler(!lightboxToggler); // Alterna el estado del lightbox
  };

  // Function to determine the class based on the index
  const getClassNames = (index) => {
    if (index % 6 === 0) return "col-span-2 row-span-2";
    if (index % 4 === 0) return "col-span-1 row-span-2";
    return "col-span-1 row-span-1";
  };

  return (
    <section>
      <ScrollToTop />
      <Banner
        title={"Galería de Imágenes"}
        bgImage={"bg-gallery"}
        bgOpacity={"50"}
      />
      <div className="container mx-auto px-4 py-10">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {images.map((srcImg, index) => (
            <div
              key={index}
              className={`relative overflow-hidden ${getClassNames(index)}`}
            >
              <LazyLoadImage
                src={srcImg}
                alt={`gallery-${index}`}
                className="w-full h-full object-cover transition-transform duration-300 hover:scale-105 cursor-zoom-in"
                loading="lazy"
                onClick={() => openLightbox(index)}
                aria-label={`Imagen de galería número ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <FSLightbox
        toggler={lightboxToggler}
        sources={images}
        slide={photoIndex}
      />
    </section>
  );
});

export default GalleryPage;
