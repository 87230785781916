import React from "react";

const GenerateWhatsAppLink = ({ nameButton, message, buttonClass }) => {
  const phone = "5491157133534"; // Número de teléfono al que se enviará el mensaje
  const whatsappLink = `https://wa.me/${phone}?text=${encodeURIComponent(
    message
  )}`;

  const handleClick = () => {
    window.open(whatsappLink, "_blank");
  };

  return (
    <button
      onClick={handleClick}
      className={`btn btn-lg btn-primary mt-4 ${buttonClass}`}
    >
      {nameButton}
    </button>
  );
};

export default GenerateWhatsAppLink;
