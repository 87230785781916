import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Banner from "../../components/Banner";
import { FaCircle } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css"; // Importa los estilos de Swiper
import { EffectFade, Autoplay } from "swiper";

import fielDay1 from "../../assets/img/services/diaDeCampo/fielDay1.jpg";
import fielDay2 from "../../assets/img/services/diaDeCampo/fielDay2.jpg";
import fielDay3 from "../../assets/img/services/diaDeCampo/fielDay3.jpg";
import fielDay4 from "../../assets/img/services/diaDeCampo/fielDay4.jpg";
import fielDay5 from "../../assets/img/services/diaDeCampo/fielDay5.jpg";
import fielDay6 from "../../assets/img/services/diaDeCampo/fielDay6.jpg";
import GenerateWhatsAppLink from "../../components/GenerateWhatsAppLink";

const dataWelcome = [
  {
    id: 1,
    img: fielDay1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: fielDay2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: fielDay3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: fielDay4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: fielDay5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: fielDay6,
    alt: "Estancia La Alameda",
  },
];

const dateTime = [
  {
    id: 1,
    time: "10:30",
    description: "Ingreso a la Estancia",
  },
  {
    id: 2,
    time: "12:00",
    description: "Visita guiada por el Casco Historico",
  },
  {
    id: 4,
    time: "13:00",
    description: "Almuerzo",
    menu: {
      general: [
        "Entrada: Empanada, chorizo ahumado y queso marinado de la casa",
        "Principal: Carne al asador con ensalada",
        "Postre del día",
      ],
      vegetariano: [
        "Entrada: Ensalada de verduras asadas",
        "Principal: Lasagna de vegetales",
        "Postre del día",
        "* Opción vegetariana: Informar en la reserva",
      ],
      infantil: "Menú Infantil: Plato especial cada finde con postre.",
      notas:
        "Las bebidas no están incluidas. El consumo de las mismas es a la carta.",
    },
  },
  {
    id: 5,
    time: "16:00",
    description: "Paseo en tractor por la Reserva Ecológica",
  },
  {
    id: 6,
    time: "17:00",
    description: "Merienda Campestre",
    menu: {
      general: [
        "Café, te, leche, chocolatada",
        "Pastafrola, pastelitos, budines y biscochitos",
      ],
    },
  },
  {
    id: 7,
    time: "18:30",
    description: "Fin de Jornada",
  },
];

const activities = [
  {
    id: 1,
    description: "Caminatas por el encantador predio",
  },
  {
    id: 2,
    description: "Visita a la huerta agroecologica",
  },
  {
    id: 3,
    description: "Encuentro con los animales de la Estancia",
  },
  {
    id: 4,
    description: "Paseos en tractor por la Reserva Ecológica",
  },
  {
    id: 5,
    description: "Excursión en Kayaks (Opcional)",
  },
  {
    id: 6,
    description:
      "Pesca desde la orilla o desde el muelle sobre la laguna de Chascomús",
  },
  {
    id: 7,
    description: "Canchas de fútbol, fútbol-tenis y voley",
  },
  {
    id: 8,
    description: "Cama elástica y juegos de plaza para los más chicos",
  },
];

function FieldDayPage() {
  return (
    <section>
      <ScrollToTop />
      <Banner
        title={"Día de Campo"}
        bgImage={"bg-campo"}
        bgOpacity={"50"}
        button={"Reserva tu Día"}
        message={`Hola, me gustaría hacer una reserva del día de campo.`}
        buttonClass={"mx-auto"}
      />

      <div className="container mx-auto px-4 pt-8 sm:pt-0">
        {/* Tarjetas de Información */}
        <section className="container sm:mt-10">
          <h2 className="text-3xl text-gray-800 mb-4 font-primary">
            Disfruta de un Día de Campo
          </h2>
          <p className="text-gray-600 mb-6 font-primary">
            Vivi una experiencia única en nuestro campo, con actividades para
            toda la familia y la oportunidad de conectar con la naturaleza.
            Disfruta de un ambiente relajado y una amplia gama de actividades
            diseñadas para todos los gustos.
          </p>
          <p className="text-gray-600 mb-6 font-primary">
            Historia, naturaleza, tradición, deporte, cultura, gastronomía se
            combinan en un plan perfecto!
          </p>
          <p className="text-gray-600 mb-6 font-primary">
            A partir de las 10:30 hs, nuestra tranquera estará abierta para
            darles la bienvenida a un Día de Campo en un entorno único.
          </p>
          <p className="text-gray-600 mb-6 font-primary">
            Durante todo el día podrán disfrutar de la reserva natural, recorrer
            los rincones con historia de la estancia y realizar todas las
            actividades que tenemos para que sea un Día de Campo inolvidable a
            orillas de la laguna de Chascomús.
          </p>
          <div className="border-t border-gray-300 mt-6 pt-4">
            <div className="flex flex-col justify-around sm:flex-row gap-6">
              <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
                  Cronograma
                </h3>
                <div className="space-y-6 border-l-2 border-dashed ml-2">
                  {dateTime.map((date) => (
                    <div key={date.id} className="relative w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-accent"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="ml-6">
                        <h4 className="font-bold text-accent font-primary">
                          {date.time} hs
                        </h4>
                        <p className="mt-2 max-w-screen-sm text-base text-gray-600 font-primary">
                          {date.description}
                        </p>
                        {date.menu && (
                          <div className="mt-2 text-gray-600 text-sm">
                            {date.menu.general && (
                              <>
                                <h5 className="font-primary">Menú General:</h5>
                                <ul className="list-disc list-inside font-primary">
                                  {date.menu.general.map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))}
                                </ul>
                              </>
                            )}
                            {date.menu.vegetariano && (
                              <>
                                <h5 className="font-primary mt-4">
                                  Menú Vegetariano:
                                </h5>
                                <ul className="list-disc list-inside font-primary">
                                  {date.menu.vegetariano.map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))}
                                </ul>
                              </>
                            )}
                            {date.menu.infantil && (
                              <p className="mt-4 font-primary">
                                {date.menu.infantil}
                              </p>
                            )}
                            {date.menu.notas && (
                              <p className="mt-4 text-sm italic">
                                {date.menu.notas}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
                  Actividades
                </h3>
                <div className="space-y-6 border-l-2 border-dashed ml-2">
                  {activities.map((activitie) => (
                    <div key={activitie.id} className="relative w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-accent"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="ml-6">
                        <h4 className="text-base text-gray-600 font-primary">
                          {activitie.description}
                        </h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
                Tarifas Sábados y Domingos
              </h3>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  <FaCircle className="text-accent text-[6px]" />
                  ADULTOS: $40.000
                </span>
              </p>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  <FaCircle className="text-accent text-[6px]" />
                  MENORES DE 8 AÑOS: $20.000
                </span>
              </p>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  <FaCircle className="text-accent text-[6px]" />
                  PACK FAMILIAR: $110.000 (2 ADULTOS 2 MENORES)
                </span>
              </p>
            </div>
            <div className="my-6 flex flex-col items-start">
              <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
                ¿Querés disfrutar de un Día de Campo en Estancia La Alameda?
                ¡Escribinos!
              </h3>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  Podés armar el Día de Campo a tu medida, la merienda campestre
                  y las actividades son optativas.
                </span>
              </p>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  No olvides traer lonas o reposeras para disfrutar del parque y
                  aire libre sobre la laguna. Equipo de mate. ¡Y el repelente!
                </span>
              </p>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  <FaCircle className="text-accent text-[6px]" />
                  Estacionamiento libre dentro de la estancia.
                </span>
              </p>
              <p className="flex text-gray-600 mb-2 font-primary">
                <span className="flex items-center gap-x-2">
                  <FaCircle className="text-accent text-[6px]" />
                  (*) Consulte por promociones grupales.
                </span>
              </p>
              <div>
                <GenerateWhatsAppLink
                  nameButton={"Realizar Reserva"}
                  message={`Hola, me gustaría hacer una reserva del día de campo.`}
                  buttonClass={"w-full"}
                />
              </div>
            </div>
            <div className="welcome-slider overflow-hidden">
              <Swiper
                modules={[EffectFade, Autoplay]} // Habilita módulos opcionales
                effect={"fade"}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                }}
              >
                {dataWelcome.map((data) => (
                  <SwiperSlide key={data.id}>
                    <div className="h-[425px]">
                      <img
                        className="w-full h-full object-cover"
                        src={data.img}
                        alt={data.alt}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        {/* Sección de Testimonios */}
        <div className="text-center my-6">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-primary font-bold mb-4">
              {" "}
              INFORMACIÓN IMPORTANTE
            </h2>
            <p className="text-lg font-secondary text-gray-700 mb-4">
              * No se suspende el día de campo por lluvia. Se realizan todas las
              actividades al aire libre en la medida de lo posible. De todos
              modos, se puede reprogramar la reserva con 24 hs de anticipación a
              la fecha reservada.
            </p>
            <p className="text-lg font-secondary text-gray-700 mb-4">
              * Las reservas se hacen efectivas una vez recibido el 50% de seña
            </p>
            <p className="text-lg font-secondary text-gray-700 mb-4">
              * Contamos con alternativas para personas vegetarianas y celíacas.
              Dar aviso al momento de realizar la reserva.
            </p>
            <a
              href="/politicasDeReserva"
              className="hover:text-accent transition"
            >
              <button className="btn btn-lg btn-primary mx-auto mt-4">
                Politicas de reservas
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FieldDayPage;
