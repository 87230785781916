import React, { useContext, useEffect } from "react";
// room context
import { RoomContext } from "../../context/RoomContext";
// headless ui menu
import { Menu } from "@headlessui/react";
// icons
import { BsChevronDown } from "react-icons/bs";
import { useParams } from "react-router-dom";

const lis = [{ name: "Hotel" }, { name: "Glamping" }];

const RoomType = () => {
  const { id } = useParams();
  const { typeRoom, setTypeRoom } = useContext(RoomContext);

  useEffect(() => {
    if (id && id >= 1 && id <= 9) {
      setTypeRoom("Hotel");
    } else if (id && id >= 10 && id <= 12) {
      setTypeRoom("Glamping");
    }
  }, [id, setTypeRoom]);

  return (
    <Menu as="div" className="w-full h-full bg-white relative">
      {/* btn */}
      <Menu.Button className="w-full h-full flex items-center justify-between px-8">
        {typeRoom}
        <BsChevronDown className="text-base text-accent-hover" />
      </Menu.Button>
      {/* items */}
      <Menu.Items
        as="ul"
        className="bg-white absolute w-full flex flex-col z-40"
      >
        {lis.map((li, index) => {
          return (
            <Menu.Item
              onClick={() => setTypeRoom(li.name)}
              as="li"
              className="border-b last-of-type:border-b-0 h-12 hover:bg-accent hover:text-white w-full flex justify-center items-center cursor-pointer"
              key={index}
            >
              {li.name}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default RoomType;
