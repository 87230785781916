import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaHiking,
  FaParking,
  FaUtensils,
  FaSwimmer,
  FaWifi,
  FaCoffee,
} from "react-icons/fa"; // Importar iconos de react-icons

function Services() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const services = [
    {
      icon: <FaHiking size={54} className="text-accent" />, // Icono de actividades al aire libre
      title: "Actividades al Aire Libre",
      description:
        "Explora una variedad de actividades al aire libre, diseñadas para conectar con la naturaleza.",
    },
    {
      icon: <FaParking size={54} className="text-accent" />, // Icono de estacionamiento
      title: "Estacionamiento",
      description:
        "Amplio estacionamiento disponible para todos nuestros huéspedes, con seguridad y fácil acceso.",
    },
    {
      icon: <FaUtensils size={54} className="text-accent" />, // Icono de restaurante
      title: "Restaurante",
      description:
        "En el comedor original de la casa, disfruta de la cocina argentina casera con vistas imponentes a la Laguna.",
    },
    {
      icon: <FaSwimmer size={54} className="text-accent" />, // Icono de piscina
      title: "Piscina al Aire Libre",
      description:
        "Refresca tu día con un chapuzón en nuestra piscina rodeada de naturaleza y vistas impresionantes.",
    },
    {
      icon: <FaWifi size={54} className="text-accent" />, // Icono de internet
      title: "Internet de Alta Velocidad",
      description:
        "Mantente conectado con nuestro internet de alta velocidad en todas las áreas de la estancia.",
    },
    {
      icon: <FaCoffee size={54} className="text-accent" />, // Icono de desayuno
      title: "Desayuno Incluido",
      description:
        "Comienza tu mañana con un delicioso desayuno casero, preparado con ingredientes frescos de la región.",
    },
  ];

  return (
    <section className="py-16 px-3 bg-white">
      <div className="container max-w-[1200px] mx-auto relative z-10">
        <div className="text-center mb-12">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Nuestros Servicios
          </div>
          <h2 className="font-primary text-[45px] mb-4">
            Instalaciones del Hotel
          </h2>
        </div>

        <div className="grid gap-8 mt-10 sm:grid-cols-2 md:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex text-center flex-col justify-center items-center bg-white shadow-md p-10 rounded-lg border border-gray-200 hover:shadow-lg transition duration-300 ease-in-out"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              {service.icon}
              <h4 className="mt-4 mb-2 text-primary font-primary text-xl tracking-[.04em] font-semibold">
                {service.title}
              </h4>
              <p className="text-gray-600 text-base font-secondary font-light tracking-[.04em]">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
