import React, { useContext, useState } from "react";
// components
import AdultsDropdown from "./AdultsDropdown";
import KidsDropdown from "./KidsDropdown";
import { RoomContext } from "../../context/RoomContext";
import CheckOut from "./CheckOut";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "./DateRangePicker";
import RoomType from "./RoomType";
import { FiSearch } from "react-icons/fi";

const BookForm = () => {
  const { handleClick, typeRoom, checkInDate, checkOutDate } =
    useContext(RoomContext); // Trae las fechas desde el contexto
  const navigate = useNavigate(); // Hook para navegación
  const [error, setError] = useState(""); // Estado para manejar los errores

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación: Asegurarse de que check-in y check-out estén seleccionados
    if (!checkInDate || !checkOutDate) {
      setError("Por favor, selecciona las fechas de check-in y check-out.");
      return; // Detiene el proceso si faltan fechas
    }

    // Limpiar el error si las fechas están correctas
    setError("");

    // Llama la función existente (si existe)
    handleClick(e);

    // Redirige según el tipo de habitación seleccionada
    if (typeRoom === "Hotel") {
      navigate("/hotel"); // Redirige a la ruta deseada
    } else {
      navigate("/glamping"); // Redirige a la ruta deseada
    }
  };

  // Calcula la cantidad de noches entre check-in y check-out
  const calculateNights = () => {
    if (checkInDate && checkOutDate) {
      const timeDiff = checkOutDate - checkInDate;
      return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Retorna el número de noches
    }
    return 0; // Retorna 0 si las fechas no están definidas
  };

  const nights = calculateNights(); // Llama a la función para obtener la cantidad de noches

  return (
    <form className="h-[350px] w-full lg:h-[70px]" onSubmit={handleSubmit}>
      <div className="flex flex-col w-full h-full lg:flex-row">
        <div className="flex gap-2 lg:gap-0 mb-2 lg:mb-0">
          <div className="flex-1">
            <DateRangePicker />
          </div>
        </div>
        <div className="flex-1 border-r mb-2 lg:mb-0">
          <AdultsDropdown />
        </div>
        <div className="flex-1 border-r mb-2 lg:mb-0">
          <KidsDropdown />
        </div>
        <div className="flex-1 border-r mb-2 lg:mb-0">
          <RoomType />
        </div>
        {/* btn */}
        <button type="submit" className="btn btn-primary">
          <FiSearch className="mr-2" />
          Buscar
        </button>
        <div className="flex flex-col lg:hidden">
          {nights > 0 && (
            <p className="mt-2 text-sm">Cantidad de Noches: {nights}</p>
          )}
          <p className="mt-2 text-sm">
            *Se concidera niño menor entre 2 a 8 años.
          </p>
        </div>
      </div>

      {/* Mostrar mensaje de error si falta el check-in o check-out */}
      {error && <p className="text-red-500 text-sm mt-4 sm:mt-2">{error}</p>}

      {/* Mostrar la cantidad de noches */}
      <div className="hidden lg:flex lg:flex-col">
        {nights > 0 && (
          <p className="mt-2 text-sm">Cantidad de Noches: {nights}</p>
        )}
        <p className="mt-2 text-sm">
          *Se concidera niño menor entre 2 a 8 años.
        </p>
      </div>
    </form>
  );
};

export default BookForm;
