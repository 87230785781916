import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper";
import Img1 from "../../assets/img/heroSlider/1.jpg";
import Img2 from "../../assets/img/heroSlider/2.jpg";
import Img3 from "../../assets/img/heroSlider/3.jpg";
import Img4 from "../../assets/img/heroSlider/4.jpg";

const slides = [
  { title: "Una Escapada Ideal", bg: Img1, btnText: "Ver Nuestras Suites" },
  { title: "A orillas de la Laguna", bg: Img2, btnText: "Ver Nuestras Suites" },
  { title: "Un Escape Natural", bg: Img3, btnText: "Ver Nuestras Suites" },
  { title: "Relajar y disfrutar", bg: Img4, btnText: "Ver Nuestras Suites" },
];

const HeroSlider = () => {
  useEffect(() => {
    const images = [Img1, Img2, Img3, Img4];

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect="fade"
      loop
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      className="heroSlider h-[600px] lg:h-[860px]"
    >
      {slides.map((slide, index) => {
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="z-20 text-white text-center">
              <div className="uppercase font-tertiary tracking-[6px] mb-5">
                Hotel Boutique
              </div>
              <h2 className="text-[32px] font-primary uppercase tracking-[2px] max-w-[920px] lg:text-[68px] leading-tight mb-6">
                {title}
              </h2>
              <a href="#rooms" className="hover:text-accent transition">
                <button className="btn btn-lg btn-primary mx-auto">
                  {btnText}
                </button>
              </a>
            </div>
            <div className="absolute top-0 w-full h-full">
              <img
                className="object-cover h-full w-full"
                src={bg}
                alt={title}
              />
            </div>
            <div className="absolute w-full h-full bg-black/60"></div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroSlider;
