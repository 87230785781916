import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { FreeMode, Navigation, Thumbs, Autoplay, EffectFade } from "swiper";
import FSLightbox from "fslightbox-react"; // Importa FSLightbox
import { formatPrice } from "../../utils/formatPrice";

// components
import ScrollToTop from "../../components/ScrollToTop";
import { RoomContext } from "../../context/RoomContext";
import {
  FaCheck,
  FaMoon,
  FaUser,
  FaChild,
  FaSignInAlt,
  FaSignOutAlt,
  FaPaw,
} from "react-icons/fa";
import BookForm from "../../components/BookForm";
import Banner from "../../components/Banner/Banner";
import GenerateWhatsAppLink from "../../components/GenerateWhatsAppLink";
import { FaTag } from "react-icons/fa";

const RoomDetailsPage = () => {
  const { rooms } = useContext(RoomContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxToggler, setLightboxToggler] = useState(false); // Estado para controlar el lightbox

  const room = rooms.find((room) => room.id === Number(id));
  useEffect(() => {
    if (!room) {
      navigate("/404"); // Redirige a la página 404
    }
  }, [room, navigate]);

  if (!room) {
    return null; // Evita renderizar el contenido si la habitación no existe
  }

  const {
    name,
    description,
    facilities,
    imageBanner,
    imageRoom,
    price,
    totalPrice,
    totalAdults,
    totalKids,
    daysBetween,
    checkInDate,
    checkOutDate,
    img360,
    img3602,
    weekdayPromotion,
  } = room;

  const openLightbox = (index) => {
    setPhotoIndex(index + 1);
    setLightboxToggler(!lightboxToggler); // Alterna el estado del lightbox
  };

  return (
    <section>
      <ScrollToTop />
      {/* banner */}
      <Banner title={name} bgImage={imageBanner} bgOpacity={"60"} />
      <div className="container mx-auto relative">
        <div className="bg-accent/20 mt-4 p-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12">
          <BookForm />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full pt-8 sm:pt-24 pb-6">
          <div className="w-full h-full lg:w-[65%] sm:px-6">
            {/* Swiper carousel */}
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
              modules={[FreeMode, Navigation, Thumbs, Autoplay, EffectFade]}
              className="mySwiper2"
              effect="fade"
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              slidesPerView={1}
              grabCursor={true}
            >
              {imageRoom.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    alt={`Room ${index}`}
                    className="w-full object-cover h-[400px] sm:h-[600px] cursor-pointer"
                    onClick={() => openLightbox(index)} // Llama a la función para abrir el lightbox
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper margin-0 padding-0 border-none h-[88px]"
              breakpoints={{
                640: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 5,
                },
              }}
            >
              {imageRoom.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    alt={`Room ${index}`}
                    className="w-full h-auto object-cover border-none mt-2 transform transition-transform duration-300 hover:scale-110 cursor-pointer"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* right */}
          <div className="w-full h-full lg:w-[35%] pt-8 sm:pt-0">
            {/* reservation */}
            <div className="py-8 px-6 bg-secondary mb-12">
              {/* rules */}
              <div>
                <h3 className="h3">{name}</h3>
                <p className="mb-4">
                  {totalPrice
                    ? "Gracias por elegir nuestras instalaciones. A continuación, revisa los detalles de tu reserva para asegurarte de que todo esté en orden."
                    : " Por favor, selecciona las fechas y el número de adultos para continuar con la reserva. Una vez completado, verás el total y podrás confirmar tu estadía."}
                </p>
                <ul className="flex flex-col gap-y-4">
                  {weekdayPromotion !== 0 && (
                    <li className="flex items-center gap-x-4">
                      <FaTag className="text-accent" />
                      Descuento: {weekdayPromotion * 100}%
                    </li>
                  )}
                  {totalAdults && (
                    <li className="flex items-center gap-x-4">
                      <FaMoon className="text-accent" />
                      Noches: {daysBetween}
                    </li>
                  )}
                  {totalAdults && (
                    <li className="flex items-center gap-x-4">
                      <FaUser className="text-accent" />
                      Adultos: {totalAdults}
                    </li>
                  )}
                  {totalKids >= 0 && (
                    <li className="flex items-center gap-x-4">
                      <FaChild className="text-accent" />
                      Menores: {totalKids}
                    </li>
                  )}
                  <li className="flex items-center gap-x-4">
                    <FaSignInAlt className="text-accent" />
                    Check-in:{" "}
                    {checkInDate ? `${checkInDate} - 12:00Hs` : "12:00Hs"}
                  </li>
                  <li className="flex items-center gap-x-4">
                    <FaSignOutAlt className="text-accent" />
                    Check-out:{" "}
                    {checkOutDate ? `${checkOutDate} - 10:00Hs` : "10:00Hs"}
                  </li>
                  <li className="flex items-center gap-x-4">
                    <FaPaw className="text-accent" />
                    No se permiten animales
                  </li>
                </ul>
              </div>

              {totalPrice ? (
                <GenerateWhatsAppLink
                  nameButton={
                    <div className="flex flex-col items-center">
                      {weekdayPromotion !== 0 &&
                        totalPrice &&
                        price !== totalPrice && (
                          <div className="text-gray-400 line-through">
                            Antes: ${formatPrice(price)}
                          </div>
                        )}
                      Reservar por ${formatPrice(totalPrice)}
                    </div>
                  }
                  message={`Hola, me gustaría reservar una habitación con los siguientes detalles:
                  - Habitación: ${name}
                  - Fecha de Check-in: ${checkInDate}
                  - Fecha de Check-out: ${checkOutDate}
                  - Noches: ${daysBetween}
                  - Adultos: ${totalAdults}
                  - Niños: ${totalKids}
                  - Precio Total: ${formatPrice(totalPrice)}`}
                  buttonClass={"w-full"}
                />
              ) : (
                <div className="btn btn-lg btn-primary w-full mt-4">
                  Reserva desde ${formatPrice(price)}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* description */}
        <section className="container sm:mt-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-4 font-primary">
            {name}
          </h2>
          <div className="text-gray-600 mb-6 font-primary">
            <p
              className="text-gray-600 mb-6 font-primary"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="border-t border-gray-300 mt-6 pt-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
              Check-in
            </h3>
            <p className="text-gray-600 mb-4 font-primary">
              <span className="flex items-center gap-x-4">
                <FaCheck className="text-accent" />
                Check-in: {id <= 9 ? "12:00 HS" : "13:00 HS"}
              </span>
            </p>
            <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
              Check-out
            </h3>
            <p className="flex text-gray-600 mb-4 font-primary">
              <span className="flex items-center gap-x-4">
                <FaCheck className="text-accent" />
                Check-out: {id <= 9 ? "10:00 HS" : "11:00 HS"}
              </span>
            </p>
            <p className="text-gray-600 mb-4 font-primary">
              LATE CHECK OUT: $8000 POR PERSONA
            </p>
            <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
              Instrucciones especiales para el check-in
            </h3>
            <p className="text-gray-600 mb-4 font-primary">
              {id <= 9
                ? // Habitaciones de hotel
                  "La estadía incluye desayuno y cena (media pensión) los fines de semana (Viernes, Sabado y Domingo). Durante los días de semana, solo incluye desayuno y almuerzo (media pensión) de (Lunes a Jueves). Las tarifas de hotel son por persona."
                : // Glamping
                  "La estadía incluye solo desayuno. Las tarifas del glamping son por domo para dos personas."}{" "}
              Los servicios de cena, almuerzo y merienda se pueden tomar en el
              restaurante de la estancia con reserva previa.
            </p>

            <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
              Mascotas
            </h3>
            <p className="text-gray-600 mb-4 font-primary">
              No se admiten mascotas
            </p>
            <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
              Niños
            </h3>
            <p className="text-gray-600 mb-4 font-primary">
              Los menores de 2 a 8 años abonan el 50% de la tarifa.
            </p>
          </div>
          {/* facilities */}
          <div className="mt-10">
            {/* grid */}
            <div className="grid grid-cols-3 gap-6 mb-12">
              {facilities.map((item, index) => {
                const { name, icon } = item;
                return (
                  <div className="flex items-center gap-x-3 flex-1" key={index}>
                    <div className="text-3xl text-accent">{icon}</div>
                    <div className="text-base">{name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="container">
          {/* 360 */}
          {img360 && (
            <>
              <h3 className="text-xl font-bold mb-4">360° Photos</h3>
              <div>
                <h3 className="h3 mb-3">Vista 360° Interior</h3>
                <div className="h-[500px] relative mb-10">
                  <iframe
                    src={img360}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    frameBorder="0"
                    allowFullScreen
                    title="360 Panorama"
                  ></iframe>
                </div>
              </div>
            </>
          )}
          {img3602 && (
            <div>
              <h3 className="h3 mb-3">Vista 360° Exterior</h3>
              <div className="h-[500px] relative mb-10">
                <iframe
                  src={img3602}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allowFullScreen
                  title="360 Panorama"
                ></iframe>
              </div>
            </div>
          )}
        </section>
      </div>
      <FSLightbox
        toggler={lightboxToggler}
        sources={imageRoom}
        slide={photoIndex}
      />
    </section>
  );
};

export default RoomDetailsPage;
