import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Banner from "../../components/Banner";

function ReservationPolicies() {
  return (
    <section>
      <ScrollToTop />
      <Banner
        title={"Políticas de Reserva"}
        bgImage={"bg-policies"}
        bgOpacity={"50"}
      />
      <div className="container mx-auto py-6 sm:py-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 font-primary">
          Información General
        </h2>
        <div className="text-gray-600 mb-6 font-primary"></div>
        <div className="border-t border-gray-300 mt-6 pt-4">
          <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
            MASCOTAS
          </h3>
          <p className="text-gray-600 mb-4 font-primary">
            NO se aceptan mascotas. Está prohibido el ingreso con alimentos y/o
            bebidas. La Estancia provee servicio de comidas. Está permitido y
            recomendamos traer equipo de mate, reposera o lonas.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
            POLÍTICAS DE RESERVA
          </h3>
          <p className="text-gray-600 mb-4 font-primary">
            <span className="underline">
              Los valores están expresados para pagos en efectivo o
              transferencia.
            </span>{" "}
            Para confirmar la reserva, se solicita una seña del 50% del total.
            El resto se abona al ingresar en efectivo o transferencia.{" "}
            <span className="underline">
              Las tarjetas de crédito y débito tienen un 10% de recargo tanto
              para la seña como para el saldo restante.
            </span>
          </p>
          <p className="text-gray-600 mb-4 font-primary">
            De lunes a jueves, las reservas de hotel incluyen la misma cantidad
            de desayunos y almuerzos que noches contratadas. Las noches de
            viernes, sábado y domingo incluyen la misma cantidad de desayunos y
            cenas que noches contratadas.
          </p>
          <p className="text-gray-600 mb-4 font-primary">
            Las reservas de Glamping pueden contratar comidas adicionales a
            través de recepción o por WhatsApp.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
            CANCELACIÓN
          </h3>
          <p className="text-gray-600 mb-4 font-primary">
            Las reservas de alojamiento y Día de Campo no admiten cambios de
            fechas ni cancelaciones. Por este motivo, los conceptos abonados en
            forma de seña o el pago total no tienen devolución. Les recomendamos
            consultar con algún familiar o conocido que quiera tomar la reserva,
            para no perder el monto abonado.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
            HORARIOS
          </h3>
          <p className="text-gray-600 mb-4 font-primary">
            El horario de administración es de 9 a 19 hs. El check-in para el
            hotel se realiza a partir de las 12 hs, para el Glamping a partir de
            las 13 hs, y hasta las 19 hs sin excepción.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-2 font-primary">
            A TENER EN CUENTA
          </h3>
          <p className="text-gray-600 mb-4 font-primary">
            Los huéspedes pueden ingresar y salir de la Estancia en el horario
            que deseen. Esperamos que disfruten de su estadía rodeados de
            naturaleza y tranquilidad.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ReservationPolicies;
