import React, { useState, useEffect } from "react";
import LogoWhite from "../../assets/img/logo-white.svg";

const Header = () => {
  const [header, setHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define the image dimensions (replace with actual dimensions if known)
  const logoWidth = 160; // Example width, replace with actual
  const logoHeight = 40; // Example height, replace with actual

  return (
    <header
      id="header"
      className={`${
        header ? "bg-white py-6 shadow-lg" : "bg-transparent py-8"
      } fixed z-50 w-full transition-all duration-500`}
    >
      <div className="lg:container lg:mx-auto flex flex-col items-center justify-between lg:flex-row">
        <div className="flex justify-between w-full px-4">
          <a href="/">
            <img
              className="w-[160px]"
              src={header ? LogoWhite : LogoWhite}
              alt="Logo"
              width={logoWidth}
              height={logoHeight}
            />
          </a>
          <div className="lg:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`focus:outline-none ml-4 cursor-pointer ${
                header ? "text-primary" : "text-white"
              }`}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>

        <nav
          className={`${
            isOpen
              ? "flex flex-col items-center w-full gap-8 lg:bg-transparent animate-slideDown"
              : "hidden"
          } lg:flex lg:flex-row lg:items-center lg:gap-x-8 lg:w-auto lg:py-0 lg:bg-transparent py-8 font-tertiary tracking-[3px] text-[15px] uppercase ${
            header
              ? "text-primary bg-white"
              : "lg:text-white text-black bg-white mt-4"
          }`}
        >
          <a
            href="/"
            className="hover:text-accent transition duration-300 ease-in-out"
          >
            Home
          </a>
          <a
            href="/hotel"
            className="hover:text-accent transition duration-300 ease-in-out"
          >
            Hotel
          </a>
          <a
            href="/glamping"
            className="hover:text-accent transition duration-300 ease-in-out"
          >
            Glamping
          </a>
          <a
            href="/servicios/restaurante"
            className="hover:text-accent transition duration-300 ease-in-out"
          >
            Restaurante
          </a>
          <a
            href="/servicios/diaDeCampo"
            className="hover:text-accent transition duration-300 ease-in-out whitespace-nowrap"
          >
            Día de campo
          </a>
          <a
            href="/servicios/eventos"
            className="hover:text-accent transition duration-300 ease-in-out"
          >
            Eventos
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
