import React, { useContext, useEffect } from "react";
import Banner from "../../components/Banner";
import BookForm from "../../components/BookForm";
import { RoomContext } from "../../context/RoomContext";
import Room from "../../components/Room";
import { SpinnerDotted } from "spinners-react";
import Alert from "../../components/Alert"; // Importa el nuevo componente de alerta

function GlampingPage() {
  const { rooms, loading, setTypeRoom } = useContext(RoomContext);

  // Filtrar habitaciones de Glamping
  const filteredRooms = rooms.filter((room) => room.typeRoom === "Glamping");

  // Cambiar el tipo de habitación a "Glamping" cuando se entra en esta página
  useEffect(() => {
    setTypeRoom("Glamping"); // Cambiar el tipo de habitación a "Glamping"
  }, [setTypeRoom]);

  return (
    <section>
      {loading && (
        <div className="h-screen fixed bottom-0 top-0 bg-black/90 w-full z-50 flex justify-center items-center">
          <SpinnerDotted color="white" />
        </div>
      )}
      <Banner title={"Glamping"} bgImage={"bg-glamping2"} bgOpacity={"50"} />
      <div className="container mx-auto relative">
        <div className="bg-accent/20 mt-4 p-4 lg:shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12">
          <BookForm />
        </div>
      </div>
      <div className="container mx-auto lg:px-0 py-20">
        <div className="text-center">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Orillas de la Laguna
          </div>
          <h2 className="font-primary text-[45px] mb-4">Nuestros Glamping</h2>
        </div>

        {/* Alerta de máximo de personas usando el componente Alert */}
        <Alert message="Cada glamping es adecuado para un máximo de 2 personas, consultar por plaza extra." />

        {filteredRooms.length === 0 ? (
          <div className="text-center mt-10">
            <p className="text-2xl font-semibold">
              No se encontraron Glamping disponibles.
            </p>
            <p className="text-lg mt-4">
              Por favor, vuelve a intentarlo más tarde o contáctanos para más
              información.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 max-w-sm mx-auto gap-[30px] md:grid-cols-2 lg:grid-cols-3 md:max-w-none lg:max-w-none lg:mx-0">
            {filteredRooms.map((room) => (
              <div key={room.id}>
                <Room room={room} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default GlampingPage;
