import React, { useContext, useEffect } from "react";
// context
import { RoomContext } from "../../context/RoomContext";
import { formatPrice } from "../../utils/formatPrice";

// loader
import { SpinnerDotted } from "spinners-react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams } from "react-router-dom";

const View360 = ({ bg }) => {
  const { id } = useParams();
  const { rooms, loading, setTypeRoom } = useContext(RoomContext);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Configurar duración de las animaciones
  }, []);

  const tenthRoom = rooms.length >= 10 ? rooms.slice(9, 10) : [];

  // Cambiar el tipo de habitación a "Glamping" cuando se entra en esta página
  useEffect(() => {
    if (id && id >= 1 && id <= 9) {
      setTypeRoom("Hotel");
    } else if (id && id >= 10 && id <= 12) {
      setTypeRoom("Glamping");
    }
  }, [id, setTypeRoom]);

  return (
    <section id="glamping" className={`py-10 ${bg}`}>
      <div className="container max-w-[1400px] mx-auto">
        <div className="text-center">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            A orillas de la laguna
          </div>
          <h2 className="font-primary text-[45px] mb-4">Glamping en 360°</h2>
        </div>
        <div className="h-[600px] relative mb-10">
          {loading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-secondary bg-opacity-75">
              <SpinnerDotted
                size={80}
                thickness={100}
                speed={100}
                color="#967143"
              />
              <p className="mt-4 text-white font-secondary text-lg">
                Cargando panorama...
              </p>
            </div>
          )}
          <iframe
            src="https://kuula.co/share/5nYjW?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameBorder="0"
            allow="accelerometer; xr-spatial-tracking; gyroscope"
            allowFullScreen
            title="360 Panorama"
          ></iframe>
        </div>
        {tenthRoom.length > 0 ? (
          tenthRoom.map((room) => (
            <Link
              to={`/room/${room.id}`}
              className="btn btn-secondary btn-sm max-w-[260px] mx-auto"
              key={room.id}
            >
              Reserva desde ${formatPrice(room.price)}
            </Link>
          ))
        ) : (
          <p className="text-center text-white">No room available to book.</p>
        )}
      </div>
    </section>
  );
};

export default View360;
