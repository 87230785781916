// rooms francesa
import francesa1 from "./assets/img/rooms/francesa/francesa1.jpg";
import francesa2 from "./assets/img/rooms/francesa/francesa2.jpg";
import francesa3 from "./assets/img/rooms/francesa/francesa3.jpg";
import francesa4 from "./assets/img/rooms/francesa/francesa4.jpg";
import francesa5 from "./assets/img/rooms/francesa/francesa5.jpg";
import francesa6 from "./assets/img/rooms/francesa/francesa6.jpg";
import francesa7 from "./assets/img/rooms/francesa/francesa7.jpg";
import francesa8 from "./assets/img/rooms/francesa/francesa8.jpg";

// rooms italiana
import italiana1 from "./assets/img/rooms/italiana/italiana1.jpg";
import italiana2 from "./assets/img/rooms/italiana/italiana2.jpg";
import italiana3 from "./assets/img/rooms/italiana/italiana3.jpg";
import italiana4 from "./assets/img/rooms/italiana/italiana4.jpg";
import italiana5 from "./assets/img/rooms/italiana/italiana5.jpg";
import italiana6 from "./assets/img/rooms/italiana/italiana6.jpg";

// rooms arentina
import argentina1 from "./assets/img/rooms/argentina/argentina1.jpg";
import argentina2 from "./assets/img/rooms/argentina/argentina2.jpg";
import argentina3 from "./assets/img/rooms/argentina/argentina3.jpg";
import argentina4 from "./assets/img/rooms/argentina/argentina4.jpg";

// rooms artDeco
import artDeco1 from "./assets/img/rooms/artDeco/artDeco1.jpg";
import artDeco2 from "./assets/img/rooms/artDeco/artDeco2.jpg";
import artDeco3 from "./assets/img/rooms/artDeco/artDeco3.jpg";
import artDeco4 from "./assets/img/rooms/artDeco/artDeco4.jpg";

// rooms holandesa
import holandesa1 from "./assets/img/rooms/holandesa/holandesa1.jpg";
import holandesa2 from "./assets/img/rooms/holandesa/holandesa2.jpg";
import holandesa3 from "./assets/img/rooms/holandesa/holandesa3.jpg";
import holandesa4 from "./assets/img/rooms/holandesa/holandesa4.jpg";
import holandesa5 from "./assets/img/rooms/holandesa/holandesa5.jpg";

// rooms magnolia
import magnolia1 from "./assets/img/rooms/magnolia/magnolia1.jpg";
import magnolia2 from "./assets/img/rooms/magnolia/magnolia2.jpg";
import magnolia3 from "./assets/img/rooms/magnolia/magnolia3.jpg";
import magnolia4 from "./assets/img/rooms/magnolia/magnolia4.jpg";

// rooms cipres
import cipres1 from "./assets/img/rooms/cipres/cipres1.jpg";
import cipres2 from "./assets/img/rooms/cipres/cipres2.jpg";
import cipres3 from "./assets/img/rooms/cipres/cipres3.jpg";
import cipres4 from "./assets/img/rooms/cipres/cipres4.jpg";

// rooms aljibe
import aljibe1 from "./assets/img/rooms/aljibe/aljibe1.jpg";
import aljibe2 from "./assets/img/rooms/aljibe/aljibe2.jpg";
import aljibe3 from "./assets/img/rooms/aljibe/aljibe3.jpg";
import aljibe4 from "./assets/img/rooms/aljibe/aljibe4.jpg";
import aljibe5 from "./assets/img/rooms/aljibe/aljibe5.jpg";
import aljibe6 from "./assets/img/rooms/aljibe/aljibe6.jpg";
import aljibe7 from "./assets/img/rooms/aljibe/aljibe7.jpg";

// rooms palmeras
import palmeras1 from "./assets/img/rooms/palmeras/palmeras1.jpg";
import palmeras2 from "./assets/img/rooms/palmeras/palmeras2.jpg";
import palmeras3 from "./assets/img/rooms/palmeras/palmeras3.jpg";
import palmeras4 from "./assets/img/rooms/palmeras/palmeras4.jpg";
import palmeras5 from "./assets/img/rooms/palmeras/palmeras5.jpg";
import palmeras6 from "./assets/img/rooms/palmeras/palmeras6.jpg";

// rooms glamping
import glamping1 from "./assets/img/rooms/glamping/glamping1.jpg";
import glamping2 from "./assets/img/rooms/glamping/glamping2.jpg";
import glamping3 from "./assets/img/rooms/glamping/glamping3.jpg";
import glamping4 from "./assets/img/rooms/glamping/glamping4.jpg";
import glamping5 from "./assets/img/rooms/glamping/glamping5.jpg";
import glamping6 from "./assets/img/rooms/glamping/glamping6.jpg";
import glamping7 from "./assets/img/rooms/glamping/glamping7.jpg";

// import icons
import {
  FaWifi,
  FaCoffee,
  FaParking,
  FaSwimmingPool,
  FaSnowflake,
  FaUtensils,
} from "react-icons/fa";

export const roomData = [
  {
    id: 1,
    name: "Suite Francesa",
    typeRoom: "Hotel",
    minTwoNightsWeekend: true,
    description:
      "Sumérgete en la elegancia y el encanto de la Suite Francesa, una habitación donde el lujo y la historia se entrelazan para ofrecerte una experiencia única. Este espacio exquisitamente decorado te transportará a la sofisticación del estilo francés, con detalles cuidadosamente seleccionados que evocan una sensación de romance y tranquilidad.<br><br>La suite cuenta con una cama king-size, ideal para disfrutar de un descanso reparador en un ambiente sereno. Las amplias ventanas permiten la entrada de luz natural, destacando los tonos suaves y los acabados de alta calidad presentes en toda la habitación. Un área de estar cómoda y acogedora complementa el espacio, ofreciendo el lugar perfecto para relajarse con un buen libro o una copa de vino.<br><br>El baño en suite, con sus acabados en mármol y elegantes detalles, ofrece una experiencia de spa privada. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de lujosos productos de tocador.<br><br>Además, la Suite Francesa ofrece vistas espectaculares del entorno natural que rodea a Estancia La Alameda, permitiéndote conectar con la belleza del paisaje desde la comodidad de tu habitación.<br><br>Esta suite es perfecta para parejas que buscan una escapada romántica o para aquellos que desean disfrutar de un retiro en un ambiente refinado y tranquilo. ¡Ven y descubre el encanto atemporal de la Suite Francesa!",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 35,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_FRANCESA_PRICE), // Precio base para 1 noche
    priceDifference: Number(
      process.env.REACT_APP_ROOM_FRANCESA_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0,
    imageBanner: "bg-francesa",
    imageCard: francesa1,
    imageRoom: [
      francesa1,
      francesa2,
      francesa3,
      francesa4,
      francesa5,
      francesa6,
      francesa7,
      francesa8,
    ],
    img360:
      "https://kuula.co/share/5xHx1?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1&inst=es",
  },
  {
    id: 2,
    name: "Suite Italiana",
    typeRoom: "Hotel",
    minTwoNightsWeekend: true,
    description:
      "Sumérgete en la esencia del estilo italiano en la Suite Italiana de Estancia La Alameda, donde la elegancia y la calidez mediterránea se combinan para crear un refugio encantador.<br><br>Esta habitación está cuidadosamente diseñada con detalles que evocan la sofisticación y el encanto de Italia. Disfruta de una cama king-size rodeada de una decoración que mezcla colores suaves y texturas naturales, creando un ambiente de serenidad.<br><br>La suite está iluminada por grandes ventanales que inundan el espacio con luz natural, realzando los acabados de alta calidad y los detalles artísticos que adornan cada rincón.<br><br>Un área de estar acogedora te invita a relajarte, ya sea con un libro o una copa de vino, mientras disfrutas de las vistas al paisaje que rodea la estancia.<br><br>El baño en suite ofrece una experiencia de spa privado, con una combinación de mármol y madera que añade un toque de lujo rústico.<br><br>Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador exclusivos que completan la experiencia.<br><br>La Suite Italiana es ideal para aquellos que buscan una escapada romántica o un retiro tranquilo en un entorno lleno de encanto y carácter.<br><br>Desde su decoración hasta sus vistas, cada detalle ha sido pensado para ofrecerte una estancia inolvidable. ¡Descubre la magia de Italia en el corazón de Estancia La Alameda!",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 35,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_ITALIANA_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_ITALIANA_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0,
    imageBanner: "bg-italiana",
    imageCard: italiana1,
    imageRoom: [
      italiana1,
      italiana2,
      italiana3,
      italiana4,
      italiana5,
      italiana6,
    ],
    img360:
      "https://kuula.co/share/5j0Bj?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1&inst=es",
  },
  {
    id: 3,
    name: "Suite Argentina",
    typeRoom: "Hotel",
    minTwoNightsWeekend: true,
    description:
      "Descubre la esencia auténtica de la Suite Argentina en Estancia La Alameda, una habitación que celebra el estilo y la cultura argentina con un toque de elegancia y confort.<br><br>Esta suite está decorada con un diseño que refleja la calidez y el carácter del país, combinando detalles tradicionales con comodidades modernas. La cama king-size ofrece un descanso reparador, rodeada de una atmósfera acogedora que evoca la rica herencia argentina.<br><br>Las grandes ventanas de la suite permiten la entrada de abundante luz natural, resaltando los acabados elegantes y el mobiliario cuidadosamente seleccionado. Un área de estar confortable te invita a relajarte y disfrutar de una copa de vino o un buen libro mientras contemplas las vistas al paisaje natural.<br><br>El baño en suite, con acabados en mármol y detalles de diseño sofisticado, proporciona una experiencia de spa privada. Relájate en la bañera o disfruta de una ducha refrescante, complementada con productos de tocador de alta calidad.<br><br>La Suite Argentina es perfecta para quienes buscan una escapada tranquila con un toque cultural único. Cada detalle ha sido pensado para ofrecerte una estancia memorable en un entorno que combina el confort con el encanto de la tradición argentina.<br><br>Ven y experimenta la autenticidad y el lujo de la Suite Argentina en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_ARGENTINA_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_ARGENTINA_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0.4,
    imageBanner: "bg-argentina",
    imageCard: argentina1,
    imageRoom: [argentina1, argentina2, argentina3, argentina4],
    img360:
      "https://kuula.co/share/5jGNB?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1&inst=es",
  },
  {
    id: 4,
    name: "Suite Art Decó",
    typeRoom: "Hotel",
    minTwoNightsWeekend: true,
    description:
      "Sumérgete en el glamour y la sofisticación de la Suite Art Deco en Estancia La Alameda, una habitación que rinde homenaje al estilo elegante y distintivo de los años 20.<br><br>Esta suite está decorada con una combinación de líneas limpias y detalles ornamentales que evocan la esencia del Art Deco. La cama king-size, con su diseño lujoso, ofrece un descanso en un ambiente de refinamiento y comodidad.<br><br>Las grandes ventanas permiten la entrada de luz natural, resaltando los acabados de alta calidad y los detalles artísticos que caracterizan la habitación. Un área de estar chic y cómoda te invita a relajarte y disfrutar de una experiencia visualmente impresionante.<br><br>El baño en suite, con sus acabados en mármol y accesorios elegantes, ofrece una experiencia de spa privada. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de lujosos productos de tocador.<br><br>La Suite Art Deco es ideal para quienes buscan una escapada llena de estilo y elegancia en un entorno que combina lo mejor del pasado con las comodidades modernas.<br><br>Descubre el encanto atemporal de la Suite Art Deco y déjate envolver por una experiencia de lujo y sofisticación en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_ART_DECO_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_ART_DECO_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0.4,
    imageBanner: "bg-artDeco",
    imageCard: artDeco1,
    imageRoom: [artDeco1, artDeco2, artDeco3, artDeco4],
    img360:
      "https://kuula.co/share/5jGNY?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
  },
  {
    id: 5,
    name: "Suite Holandesa",
    typeRoom: "Hotel",
    minTwoNightsWeekend: false,
    description:
      "Explora la elegancia y el estilo único de la Suite Holandesa en Estancia La Alameda, una habitación que combina el diseño clásico con el confort moderno.<br><br>Inspirada en el estilo tradicional holandés, esta suite ofrece una atmósfera acogedora y sofisticada. La cama king-size, con un diseño elegante, proporciona un descanso reparador en un entorno sereno y refinado.<br><br>Las ventanas amplias permiten la entrada de luz natural, realzando los acabados de alta calidad y los detalles cuidadosamente seleccionados que adornan la habitación. Un área de estar confortable y bien diseñada te invita a relajarte y disfrutar de las vistas al entorno natural.<br><br>El baño en suite, con acabados en mármol y accesorios de diseño elegante, ofrece una experiencia de spa privado. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador de lujo.<br><br>La Suite Holandesa es perfecta para aquellos que buscan una escapada en un entorno refinado, con un toque de encanto tradicional. Cada detalle ha sido pensado para ofrecerte una estancia cómoda y memorable.<br><br>Ven y descubre el encanto y la elegancia de la Suite Holandesa en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: 2,
    price: Number(process.env.REACT_APP_ROOM_HOLANDESA_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_HOLANDESA_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0.4,
    imageBanner: "bg-holandesa",
    imageCard: holandesa1,
    imageRoom: [holandesa1, holandesa2, holandesa3, holandesa4, holandesa5],
    img360: "",
  },
  {
    id: 6,
    name: "Suite Magnolia",
    typeRoom: "Hotel",
    minTwoNightsWeekend: false,
    description:
      "Descubre la serenidad y el encanto de la Suite Magnolia en Estancia La Alameda, una habitación que combina elegancia y confort en un entorno acogedor.<br><br>La Suite Magnolia está decorada con un estilo refinado que evoca la belleza de la naturaleza. La cama king-size, con su diseño elegante y cómodo, garantiza un descanso reparador en un ambiente tranquilo.<br><br>Las amplias ventanas de la suite permiten que la luz natural inunde el espacio, realzando los acabados suaves y los detalles decorativos inspirados en el mundo natural. Un área de estar acogedora te invita a relajarte y disfrutar del entorno sereno.<br><br>El baño en suite, con sus acabados en mármol y accesorios sofisticados, ofrece una experiencia de spa privada. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador de alta calidad.<br><br>La Suite Magnolia es ideal para quienes buscan una escapada en un entorno lleno de tranquilidad y elegancia. Cada detalle ha sido pensado para ofrecerte una estancia memorable y reconfortante.<br><br>Ven y experimenta el encanto natural de la Suite Magnolia en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_MAGNOLIA_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_MAGNOLIA_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0.4,
    imageBanner: "bg-magnolia",
    imageCard: magnolia1,
    imageRoom: [magnolia1, magnolia2, magnolia3, magnolia4],
    img360: "",
  },
  {
    id: 7,
    name: "Suite Ciprés",
    typeRoom: "Hotel",
    minTwoNightsWeekend: false,
    description:
      "Descubre el encanto y la serenidad de la Suite del Ciprés en Estancia La Alameda, una habitación que combina la elegancia rústica con la comodidad moderna.<br><br>Inspirada en la belleza natural de los cipreses, esta suite ofrece un ambiente acogedor y tranquilo. La cama king-size, con un diseño sofisticado, garantiza un descanso reparador en un entorno de paz y confort.<br><br>Las amplias ventanas permiten la entrada de luz natural, realzando los acabados de alta calidad y los detalles decorativos que evocan la esencia de la naturaleza. Un área de estar cómoda y bien diseñada te invita a relajarte y disfrutar de las vistas al paisaje que rodea la estancia.<br><br>El baño en suite, con acabados en mármol y accesorios elegantes, proporciona una experiencia de spa privado. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador de lujo.<br><br>La Suite del Ciprés es ideal para aquellos que buscan una escapada en un entorno natural y refinado. Cada detalle ha sido cuidadosamente pensado para ofrecerte una estancia memorable y relajante.<br><br>Ven y experimenta la serenidad y el estilo de la Suite del Ciprés en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: [2, 3],
    price: Number(process.env.REACT_APP_ROOM_CIPRES_PRICE),
    priceDifference: Number(process.env.REACT_APP_ROOM_CIPRES_PRICE_DIFFERENCE),
    weekdayPromotion: 0.4,
    imageBanner: "bg-cipres",
    imageCard: cipres1,
    imageRoom: [cipres1, cipres2, cipres3, cipres4],
    img360: "",
  },
  {
    id: 8,
    name: "Suite Aljibe",
    minTwoNightsWeekend: false,
    typeRoom: "Hotel",
    description:
      "Adéntrate en la historia y el lujo de la Suite del Aljibe en Estancia La Alameda, una habitación que combina el encanto clásico con el confort moderno.<br><br>Inspirada en la rica tradición de la estancia, esta suite ofrece un ambiente acogedor y elegante. La cama king-size, con su diseño refinado, garantiza un descanso reparador en un entorno de tranquilidad y sofisticación.<br><br>Las grandes ventanas de la suite permiten la entrada de luz natural, destacando los acabados de alta calidad y los detalles decorativos que evocan la historia de la estancia. Un área de estar cómoda y bien diseñada te invita a relajarte y disfrutar de las vistas al paisaje circundante.<br><br>El baño en suite, con acabados en mármol y accesorios elegantes, ofrece una experiencia de spa privada. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador de lujo.<br><br>La Suite del Aljibe es ideal para quienes buscan una escapada en un entorno histórico con un toque de lujo. Cada detalle ha sido pensado para ofrecerte una estancia inolvidable y placentera.<br><br>Ven y descubre la elegancia y la historia de la Suite del Aljibe en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 55,
    maxPerson: [4, 5, 6],
    price: Number(process.env.REACT_APP_ROOM_ALJIBE_PRICE),
    priceDifference: Number(process.env.REACT_APP_ROOM_ALJIBE_PRICE_DIFFERENCE),
    weekdayPromotion: 0.4,
    imageBanner: "bg-aljibe",
    imageCard: aljibe1,
    imageRoom: [aljibe1, aljibe2, aljibe3, aljibe4, aljibe5, aljibe6, aljibe7],
    img360: "",
  },
  {
    id: 9,
    name: "Suite Palmeras/Glicina",
    typeRoom: "Hotel",
    description:
      "Descubre la serenidad y el lujo de la Suite Palmeras & Glicina en Estancia La Alameda, una habitación que combina la elegancia tropical con el confort moderno.<br><br>Inspirada en la belleza de las palmeras y glicinas, esta suite ofrece un ambiente relajante y sofisticado. La cama king-size, rodeada de una decoración fresca y elegante, asegura un descanso reparador en un entorno de tranquilidad.<br><br>Las amplias ventanas de la suite permiten la entrada de luz natural, resaltando los acabados de alta calidad y los detalles decorativos inspirados en la naturaleza. Un área de estar cómoda y acogedora te invita a relajarte y disfrutar de las vistas al exuberante paisaje que rodea la estancia.<br><br>El baño en suite, con acabados en mármol y accesorios elegantes, proporciona una experiencia de spa privado. Disfruta de una ducha revitalizante o relájate en la bañera, rodeado de productos de tocador de lujo.<br><br>La Suite Palmeras & Glicina es ideal para aquellos que buscan una escapada en un entorno natural y lujoso. Cada detalle ha sido pensado para ofrecerte una estancia inolvidable y rejuvenecedora.<br><br>Ven y experimenta la belleza y la elegancia de la Suite Palmeras & Glicina en Estancia La Alameda.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 55,
    maxPerson: [4, 5, 6],
    price: Number(process.env.REACT_APP_ROOM_PALMERAS_PRICE),
    priceDifference: Number(
      process.env.REACT_APP_ROOM_PALMERAS_PRICE_DIFFERENCE
    ),
    weekdayPromotion: 0.4,
    imageBanner: "bg-palmeras",
    imageCard: palmeras1,
    imageRoom: [
      palmeras1,
      palmeras2,
      palmeras3,
      palmeras4,
      palmeras5,
      palmeras6,
    ],
    img360: "",
  },
  {
    id: 10,
    name: "Glamping",
    typeRoom: "Glamping",
    minTwoNightsWeekend: true,
    description:
      "¿Quieres saber qué es y de qué se trata la experiencia de glamping en Estancia La Alameda?<br><br>El glamping es un campamento de lujo, y nuestras tiendas domo están equipadas con todas las comodidades que necesitas para una estancia inolvidable. Ofrecemos cama sommier queen, aire acondicionado frío/calor, baño privado con ducha de agua caliente, y detalles como ropa de cama de alta calidad, toallas, juego de living y una pava eléctrica con infusiones de cortesía. ¡Te vas a olvidar que estás acampando!<br><br>La capacidad de cada domo es de 2 personas, con la opción de agregar una plaza extra para un menor de 8 años abonando un adicional del 50% de la tarifa.<br><br>Lo que incluye: desayuno, uso de la pileta exterior del hotel, paseo en tractor por la reserva ecológica, y acceso exclusivo al sector de la laguna. Las tiendas están ubicadas a pocos metros de la reserva natural de la estancia, ofreciendo el mismo confort y baño en suite que nuestras habitaciones de hotel boutique. (Ten en cuenta que actividades como el paseo en tractor se cancelan en caso de lluvia).<br><br>Adjuntamos toda la información y tarifas actuales en un archivo.<br><br>Los horarios de ingreso son a partir de las 13:00 hs y el egreso a las 11:00 hs. Después de entregar el glamping, puedes quedarte a disfrutar del día en la estancia sin costo adicional. La recepción está abierta hasta las 19:00 hs. (Si deseas ingresar después de este horario, te pedimos que nos avises para recibirte).<br><br>Para reservar, se requiere una seña del 50% del total mediante transferencia o depósito bancario. El saldo restante se abona al ingresar. Los valores son para pago en efectivo o transferencia, y si deseas pagar con tarjeta de débito o crédito, tiene un 10% de recargo.<br><br>Los servicios de cena, almuerzo y merienda se pueden tomar en el restaurante de la estancia con reserva previa.<br><br>No está permitido el ingreso con animales, comidas ni bebidas.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: 2,
    price: Number(process.env.REACT_APP_GLAMPING_PRICE),
    priceDifference: Number(process.env.REACT_APP_GLAMPING_PRICE_DIFFERENCE),
    weekdayPromotion: 0,
    imageBanner: "bg-glamping",
    imageCard: glamping1,
    imageRoom: [
      glamping1,
      glamping2,
      glamping3,
      glamping4,
      glamping5,
      glamping6,
      glamping7,
    ],
    img360:
      "https://kuula.co/share/5nYsQ?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
    img3602:
      "https://kuula.co/share/584KL?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
  },
  {
    id: 11,
    name: "Glamping",
    typeRoom: "Glamping",
    minTwoNightsWeekend: true,
    description:
      "¿Quieres saber qué es y de qué se trata la experiencia de glamping en Estancia La Alameda?<br><br>El glamping es un campamento de lujo, y nuestras tiendas domo están equipadas con todas las comodidades que necesitas para una estancia inolvidable. Ofrecemos cama sommier queen, aire acondicionado frío/calor, baño privado con ducha de agua caliente, y detalles como ropa de cama de alta calidad, toallas, juego de living y una pava eléctrica con infusiones de cortesía. ¡Te vas a olvidar que estás acampando!<br><br>La capacidad de cada domo es de 2 personas, con la opción de agregar una plaza extra para un menor de 8 años abonando un adicional del 50% de la tarifa.<br><br>Lo que incluye: desayuno, uso de la pileta exterior del hotel, paseo en tractor por la reserva ecológica, y acceso exclusivo al sector de la laguna. Las tiendas están ubicadas a pocos metros de la reserva natural de la estancia, ofreciendo el mismo confort y baño en suite que nuestras habitaciones de hotel boutique. (Ten en cuenta que actividades como el paseo en tractor se cancelan en caso de lluvia).<br><br>Adjuntamos toda la información y tarifas actuales en un archivo.<br><br>Los horarios de ingreso son a partir de las 13:00 hs y el egreso a las 11:00 hs. Después de entregar el glamping, puedes quedarte a disfrutar del día en la estancia sin costo adicional. La recepción está abierta hasta las 19:00 hs. (Si deseas ingresar después de este horario, te pedimos que nos avises para recibirte).<br><br>Para reservar, se requiere una seña del 50% del total mediante transferencia o depósito bancario. El saldo restante se abona al ingresar. Los valores son para pago en efectivo o transferencia, y si deseas pagar con tarjeta de débito o crédito, tiene un 10% de recargo.<br><br>Los servicios de cena, almuerzo y merienda se pueden tomar en el restaurante de la estancia con reserva previa.<br><br>No está permitido el ingreso con animales, comidas ni bebidas.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: 2,
    price: Number(process.env.REACT_APP_GLAMPING_PRICE),
    priceDifference: Number(process.env.REACT_APP_GLAMPING_PRICE_DIFFERENCE),
    weekdayPromotion: 0,
    imageBanner: "bg-glamping",
    imageCard: glamping2,
    imageRoom: [
      glamping1,
      glamping2,
      glamping3,
      glamping4,
      glamping5,
      glamping6,
      glamping7,
    ],
    img360:
      "https://kuula.co/share/5nYsQ?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
    img3602:
      "https://kuula.co/share/584KL?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
  },
  {
    id: 12,
    name: "Glamping",
    typeRoom: "Glamping",
    minTwoNightsWeekend: true,
    description:
      "¿Quieres saber qué es y de qué se trata la experiencia de glamping en Estancia La Alameda?<br><br>El glamping es un campamento de lujo, y nuestras tiendas domo están equipadas con todas las comodidades que necesitas para una estancia inolvidable. Ofrecemos cama sommier queen, aire acondicionado frío/calor, baño privado con ducha de agua caliente, y detalles como ropa de cama de alta calidad, toallas, juego de living y una pava eléctrica con infusiones de cortesía. ¡Te vas a olvidar que estás acampando!<br><br>La capacidad de cada domo es de 2 personas, con la opción de agregar una plaza extra para un menor de 8 años abonando un adicional del 50% de la tarifa.<br><br>Lo que incluye: desayuno, uso de la pileta exterior del hotel, paseo en tractor por la reserva ecológica, y acceso exclusivo al sector de la laguna. Las tiendas están ubicadas a pocos metros de la reserva natural de la estancia, ofreciendo el mismo confort y baño en suite que nuestras habitaciones de hotel boutique. (Ten en cuenta que actividades como el paseo en tractor se cancelan en caso de lluvia).<br><br>Adjuntamos toda la información y tarifas actuales en un archivo.<br><br>Los horarios de ingreso son a partir de las 13:00 hs y el egreso a las 11:00 hs. Después de entregar el glamping, puedes quedarte a disfrutar del día en la estancia sin costo adicional. La recepción está abierta hasta las 19:00 hs. (Si deseas ingresar después de este horario, te pedimos que nos avises para recibirte).<br><br>Para reservar, se requiere una seña del 50% del total mediante transferencia o depósito bancario. El saldo restante se abona al ingresar. Los valores son para pago en efectivo o transferencia, y si deseas pagar con tarjeta de débito o crédito, tiene un 10% de recargo.<br><br>Los servicios de cena, almuerzo y merienda se pueden tomar en el restaurante de la estancia con reserva previa.<br><br>No está permitido el ingreso con animales, comidas ni bebidas.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Desayuno", icon: <FaCoffee /> },
      { name: "Estacionamiento", icon: <FaParking /> },
      { name: "Pileta exterior", icon: <FaSwimmingPool /> },
      { name: "Aire Acondicionado", icon: <FaSnowflake /> },
      { name: "Restaurante", icon: <FaUtensils /> },
    ],
    size: 30,
    maxPerson: 2,
    price: Number(process.env.REACT_APP_GLAMPING_PRICE),
    priceDifference: Number(process.env.REACT_APP_GLAMPING_PRICE_DIFFERENCE),
    weekdayPromotion: 0,
    imageBanner: "bg-glamping",
    imageCard: glamping6,
    imageRoom: [
      glamping1,
      glamping2,
      glamping3,
      glamping4,
      glamping5,
      glamping6,
      glamping7,
    ],
    img360:
      "https://kuula.co/share/5nYsQ?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
    img3602:
      "https://kuula.co/share/584KL?logo=0&info=1&fs=1&vr=0&sd=1&thumbs=1",
  },
];
