import React, { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Banner from "../../components/Banner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css"; // Importa los estilos de Swiper
import { EffectFade, Autoplay, Pagination } from "swiper";
import FSLightbox from "fslightbox-react"; // Importa FSLightbox

import socialEvents1 from "../../assets/img/services/eventos/socialEvents/socialEvents1.jpg";
import socialEvents2 from "../../assets/img/services/eventos/socialEvents/socialEvents2.jpg";
import socialEvents3 from "../../assets/img/services/eventos/socialEvents/socialEvents3.jpg";
import socialEvents4 from "../../assets/img/services/eventos/socialEvents/socialEvents4.jpg";
import socialEvents5 from "../../assets/img/services/eventos/socialEvents/socialEvents5.jpg";
import socialEvents6 from "../../assets/img/services/eventos/socialEvents/socialEvents6.jpg";
import socialEvents7 from "../../assets/img/services/eventos/socialEvents/socialEvents7.jpg";

import corporateEvents1 from "../../assets/img/services/eventos/corporateEvents/corporateEvents1.jpg";
import corporateEvents2 from "../../assets/img/services/eventos/corporateEvents/corporateEvents2.jpg";
import corporateEvents3 from "../../assets/img/services/eventos/corporateEvents/corporateEvents3.jpg";
import corporateEvents4 from "../../assets/img/services/eventos/corporateEvents/corporateEvents4.jpg";
import corporateEvents5 from "../../assets/img/services/eventos/corporateEvents/corporateEvents5.jpg";
import corporateEvents6 from "../../assets/img/services/eventos/corporateEvents/corporateEvents6.jpg";
import corporateEvents7 from "../../assets/img/services/eventos/corporateEvents/corporateEvents7.jpg";
import corporateEvents8 from "../../assets/img/services/eventos/corporateEvents/corporateEvents8.jpg";

import productionEvents1 from "../../assets/img/services/eventos/productionEvents/productionEvents1.jpg";
import productionEvents2 from "../../assets/img/services/eventos/productionEvents/productionEvents2.jpg";
import productionEvents3 from "../../assets/img/services/eventos/productionEvents/productionEvents3.jpg";
import productionEvents4 from "../../assets/img/services/eventos/productionEvents/productionEvents4.jpg";
import productionEvents5 from "../../assets/img/services/eventos/productionEvents/productionEvents5.jpg";
import productionEvents6 from "../../assets/img/services/eventos/productionEvents/productionEvents6.jpg";
import productionEvents7 from "../../assets/img/services/eventos/productionEvents/productionEvents7.jpg";
import productionEvents8 from "../../assets/img/services/eventos/productionEvents/productionEvents8.jpg";
import GenerateWhatsAppLink from "../../components/GenerateWhatsAppLink";

// Imágenes para Eventos Empresariales
const corporateEventsImg = [
  {
    id: 1,
    img: corporateEvents1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: corporateEvents2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: corporateEvents3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: corporateEvents4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: corporateEvents5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: corporateEvents6,
    alt: "Estancia La Alameda",
  },
  {
    id: 7,
    img: corporateEvents7,
    alt: "Estancia La Alameda",
  },
  {
    id: 8,
    img: corporateEvents8,
    alt: "Estancia La Alameda",
  },
];

// Imágenes para Producciones
const productionsImg = [
  {
    id: 1,
    img: productionEvents1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: productionEvents2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: productionEvents3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: productionEvents4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: productionEvents5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: productionEvents6,
    alt: "Estancia La Alameda",
  },
  {
    id: 7,
    img: productionEvents7,
    alt: "Estancia La Alameda",
  },
  {
    id: 8,
    img: productionEvents8,
    alt: "Estancia La Alameda",
  },
];

// Imágenes para Eventos Sociales
const socialEventsImg = [
  {
    id: 1,
    img: socialEvents1,
    alt: "Estancia La Alameda",
  },
  {
    id: 2,
    img: socialEvents2,
    alt: "Estancia La Alameda",
  },
  {
    id: 3,
    img: socialEvents3,
    alt: "Estancia La Alameda",
  },
  {
    id: 4,
    img: socialEvents4,
    alt: "Estancia La Alameda",
  },
  {
    id: 5,
    img: socialEvents5,
    alt: "Estancia La Alameda",
  },
  {
    id: 6,
    img: socialEvents6,
    alt: "Estancia La Alameda",
  },
  {
    id: 7,
    img: socialEvents7,
    alt: "Estancia La Alameda",
  },
];

// Textos para las secciones
const socialEvents = (
  <>
    <h1 className="font-primary text-2xl sm:text-[38px] tracking-[.04em] font-normal py-3 text-black mb-3">
      Eventos Sociales
    </h1>
    <p className="opacity-60 font-light font-secondary text-justify text-base sm:text-lg text-black">
      Sabemos que en la vida hay momentos que deseamos que sean inolvidables…
      nosotros te ofrecemos un marco único e inigualable para que así sea. Vista
      soñada a la Laguna que brinda un paisaje incomparable para cualquier
      evento, añosa arboleda, vívidos atardeceres e instalaciones que se adaptan
      para una ocasión íntima como así también para un evento con más de 2000
      personas, son algunos elementos que hacen de Estancia La Alameda el lugar
      ideal para la celebración de casamientos, cumpleaños, aniversario o
      cualquier tipo de reunión social en un espacio que se adapta a cualquier
      tipo de necesidad y con un entorno único.
    </p>

    <GenerateWhatsAppLink
      nameButton={"Consultar por Evento"}
      message={`Hola, me gustaría obtener más información sobre los eventos.`}
      buttonClass={"w-full"}
    />
  </>
);

const corporateEvents = (
  <>
    <h1 className="font-primary text-2xl sm:text-[38px] tracking-[.04em] font-normal py-3 text-black mb-3">
      Eventos Empresariales
    </h1>
    <p className="opacity-60 font-light font-secondary text-justify text-base sm:text-lg text-black">
      ¡Hacé que tu evento sorprenda! En Estancia La Alameda te ofrecemos
      infinidad de posibilidades para realizar tu evento, ya sea al aire libre
      como en un ambiente cerrado, con un marco inigualable a orillas de la
      Laguna. Nuestro salón principal, único en la zona, se puede adaptar a
      cualquier tipo de evento. Una fiesta de Fin de Año para 2000 personas,
      como una Capacitación para 50. El Restó Girado, es un ambiente más íntimo
      con una vista inmejorable ideal para poder desarrollar reuniones,
      almuerzos o cenas de trabajo para grupos reducidos. Family Days,
      Capacitaciones, Congresos, Almuerzos y cenas de trabajo, Fiestas de Fin de
      Año, Lanzamientos de Productos, seminarios.
    </p>

    <GenerateWhatsAppLink
      nameButton={"Consultar por Evento"}
      message={`Hola, me gustaría obtener más información sobre los eventos.`}
      buttonClass={"w-full"}
    />
  </>
);

const productions = (
  <>
    <h1 className="font-primary text-2xl sm:text-[38px] tracking-[.04em] font-normal py-3 text-black mb-3">
      Producciones
    </h1>
    <p className="opacity-60 font-light font-secondary text-justify text-base sm:text-lg text-black">
      Estancia La Alameda es el escenario perfecto para llevar a cabo
      producciones que capturen la esencia de la naturaleza y el aire libre.
      Nuestro entorno único, con vistas constantes a la impresionante Laguna,
      ofrece un fondo incomparable para cualquier tipo de proyecto. Ya sea que
      estés planificando una sesión fotográfica, una grabación de video o un
      evento especial, nuestras instalaciones se adaptan a tus necesidades.
      Contamos con amplios espacios y rincones llenos de historia que aportarán
      un toque distintivo a tus producciones. Solicitá una entrevista y vení a
      conocernos. Te invitamos a explorar todas las posibilidades que Estancia
      La Alameda tiene para ofrecerte.
    </p>

    <GenerateWhatsAppLink
      nameButton={"Consultar por Evento"}
      message={`Hola, me gustaría obtener más información sobre los eventos.`}
      buttonClass={"w-full"}
    />
  </>
);

function WelcomeSlider({ images, onClickImage }) {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
      }}
    >
      {images.map((data, index) => (
        <SwiperSlide key={data.id}>
          <div className="h-[425px]">
            <img
              className="w-full h-full object-cover cursor-pointer"
              src={data.img}
              alt={data.alt}
              onClick={() => onClickImage(index + 1)}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function EventsPage() {
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  const eventTexts = [socialEvents, corporateEvents, productions];
  const eventImages = [socialEventsImg, corporateEventsImg, productionsImg];

  const handleImageClick = (images, index) => {
    setSelectedImages(images.map((img) => img.img));
    setPhotoIndex(index);
    setLightboxToggler(!lightboxToggler); // Abre el lightbox
  };

  return (
    <section>
      <ScrollToTop />
      <Banner
        title={"Eventos"}
        bgImage={"bg-eventos"}
        bgOpacity={"50"}
        button={"Consultar por evento"}
        message={`Hola, me gustaría obtener más información sobre los eventos.`}
      />

      <div className="container mx-auto px-4">
        {/* Secciones Reutilizables */}
        {eventTexts.map((eventText, index) => (
          <section
            className="min-h-[500px] mx-auto flex items-center py-4 sm:py-16"
            key={index}
          >
            <div className="container max-w-[1200px] mx-auto grid md:grid-cols-2 gap-4 sm:gap-14">
              {index % 2 === 0 ? (
                <>
                  <div className="flex flex-col justify-center items-start order-2 md:order-2">
                    {eventText}
                  </div>
                  <div className="welcome-slider overflow-hidden order-1 md:order-1">
                    <WelcomeSlider
                      images={eventImages[index]}
                      onClickImage={(imgIndex) =>
                        handleImageClick(eventImages[index], imgIndex)
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="welcome-slider overflow-hidden order-1 md:order-2">
                    <WelcomeSlider
                      images={eventImages[index]}
                      onClickImage={(imgIndex) =>
                        handleImageClick(eventImages[index], imgIndex)
                      }
                    />
                  </div>
                  <div className="flex flex-col justify-center items-start order-2 md:order-1">
                    {eventText}
                  </div>
                </>
              )}
            </div>
          </section>
        ))}
      </div>

      {/* Lightbox */}
      <FSLightbox
        toggler={lightboxToggler}
        sources={selectedImages}
        slide={photoIndex} // Establecer la imagen activa
      />
    </section>
  );
}

export default EventsPage;
