import React from "react";
import {
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
  FaEnvelope,
  FaFacebookSquare,
} from "react-icons/fa";
import LogoWhite from "../../assets/img/logo-white.svg";

function Location() {
  return (
    <div id="contact" className="py-10 mt-8 relative">
      <div className="absolute inset-0 flex justify-center rounded-md">
        <iframe
          width="90%"
          height="95%"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="map"
          scrolling="no"
          className="rounded-lg"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12973.441871929714!2d-58.0305688!3d-35.6188391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959812e73b7ae143%3A0x6aa4a76425fcb1bf!2sEstancia%20la%20Alameda!5e0!3m2!1sen!2sar!4v1723832035517!5m2!1sen!2sar"
        ></iframe>
      </div>
      <div className="container px-5 py-24 mx-auto flex">
        <div className="lg:w-1/3 md:w-1/2 bg-secondary rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative shadow-md">
          <div className="text-accent w-full">
            <img
              src={LogoWhite}
              alt="Logo de Estancia La Alameda"
              className="w-48 mb-6"
            />
            <p className="text-primary text-md font-secondary">
              Hotel Boutique - Día de Campo - Restaurant
            </p>

            <div className="mt-6 space-y-8">
              <p className="flex items-start text-primary font-secondary">
                <FaMapMarkerAlt className="text-accent w-6 h-6" />
                <span className="mx-4 truncate">
                  Camino de Circunvalación KM 12
                </span>
              </p>

              <p className="flex items-start text-primary font-secondary">
                <FaPhoneAlt className="text-accent w-6 h-6" />
                <span className="mx-4 truncate">(011) 15-5713-3534</span>
              </p>

              <p className="flex items-start text-primary font-secondary">
                <FaEnvelope className="text-accent w-6 h-6" />
                <span className="mx-4 truncate">
                  reservas@estancialaalameda.com
                </span>
              </p>
            </div>

            <div className="mt-6">
              <h3 className="text-accent font-secondary text-lg">
                Contáctanos
              </h3>

              <div className="mt-4 flex">
                <div className="ml-2 mr-2">
                  <a
                    href="https://www.instagram.com/estancialaalamedaok"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Síguenos en Instagram"
                  >
                    <FaInstagram className="h-7 w-7 text-primary hover:text-accent cursor-pointer" />
                  </a>
                </div>

                <div className="ml-2 mr-2">
                  <a
                    href="https://www.facebook.com/EstanciaLaAlamedaHotelBoutique"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Síguenos en Facebook"
                  >
                    <FaFacebookSquare className="h-7 w-7 text-primary hover:text-accent cursor-pointer" />
                  </a>
                </div>

                <div className="ml-2 mr-2">
                  <a
                    href="https://api.whatsapp.com/send?phone=5491157133534&text=Hola%20Alameda,%20quisiera%20realizar%20una%20consulta,%20Gracias!"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Envíanos un mensaje por WhatsApp"
                  >
                    <FaWhatsapp className="h-7 w-7 text-primary hover:text-accent cursor-pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
